import { Component, OnInit } from '@angular/core'
import { Cliente } from './../../model/tenant/cliente'
import { TenantService } from './../../services/tenant.service'

@Component({
  selector: 'app-smartapp-integration',
  templateUrl: './smartapp-integration.component.html'
})
export class SmartappIntegrationComponent implements OnInit {
  cliente: Cliente
  url: string

  constructor(private tenantService: TenantService) {
    this.cliente = this.tenantService.getCliente()
  }

  ngOnInit(): void {
    this.url = this.cliente.smartAppURL
  }
}
