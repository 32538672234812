import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseService } from 'app/legacy/core/services/basic.service';
import { MessagesService } from 'app/legacy/core/services/messages.service';
import { filter, find, isUndefined, sortBy, clone } from 'lodash';
import { Restangular } from 'ngx-restangular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StepParamsDialogComponent } from '../step-params-dialog/step-params-dialog.component';
import { TranslationPipe } from './../../translation/translation.pipe';

const NOTIFICATION_TIME = 3000

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnInit, OnDestroy {

  @Input() leftEntity: string;
  @Input() leftTitle: string;
  @Input() leftSubTitle: string;

  @Input() rightEntity: string;
  @Input() rightTitle: string;
  @Input() rightSubTitle: string;

  @Input() mainAttrName: string;
  @Input() mainAttrValue: any;
  @Input() secondaryAttrName: string;

  @Input() useOrder: boolean;
  @Input() filter: boolean;
  @Input() useTenant: boolean;
  @Input() defaultAttr: any;
  @Input() disabled = false;

  originalLeftList: any[] = [];
  originalRightList: any[] = [];
  leftList: any[] = [];
  leftListBackup = [];
  rightList: any[] = [];
  rightListBackup = [];

  subscriptions: Subscription;
  filterLeft = new FormControl(null);
  filterLeftList$ = new Subject();
  filterRight = new FormControl(null);
  filterRightList$ = new Subject();

  constructor(
    private restangular: Restangular,
    public snackBar: MatSnackBar,
    private messagesService: MessagesService,
    public translateService: TranslationPipe,
    private dialog: MatDialog,
  ) {
    if (isUndefined(this.useOrder)) {
      this.useOrder = true
    }
    if (isUndefined(this.useTenant)) {
      this.useTenant = false
    }
    if (isUndefined(this.defaultAttr)) {
      this.defaultAttr = {}
    }
  }

  async ngOnInit() {
    this.originalLeftList = await this.getList(this.leftEntity)

    this.originalRightList = await this.getList(this.rightEntity, { FilterProp: this.mainAttrName, FilterValue: this.mainAttrValue })
    this.originalRightList = filter(this.originalRightList, { [this.mainAttrName]: this.mainAttrValue })

    if (this.rightEntity === 'JourneyStep') {
      for (const item of this.originalRightList) {
        item.parameters = await this.getStepParams(item.id)
      }
    }

    this.rightList = this.originalRightList.map(item => item)
    this.sortRightList(true, true);
    this.rightListBackup = this.rightList;

    this.leftList = this.getFilteredLeftList();
    this.sortLeftList(true);
    this.leftListBackup = this.leftList;
    this.createSubscriptionsFilter();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async getList(entityName: string, queryParams: any = {}) {
    const service = new BaseService(this.restangular)
    service.setEntity(entityName)
    const { results = [] } = await service.findAll({ take: 1000000, ...queryParams })
    return results
  }

  async getStepParams(id) {
    const service = new BaseService(this.restangular)
    service.setEntity('StepParams')
    const { results = [] } = await service.findAll({ FilterProp: 'journeyStepId', FilterValue: id })
    return results
  }

  createSubscriptionsFilter() {
    this.subscriptions = this.filterLeftList$.pipe(
      debounceTime(750)).subscribe((name: any) => {
        this.filterLeftList(name.target?.value);
      });
    this.subscriptions.add(this.filterRightList$.pipe(
      debounceTime(750)).subscribe((name: any) => {
        this.filterRightList(name.target?.value);
      }));
  }

  filterLeftList(name?: string) {
    if (name) {
      this.leftList = this.leftListBackup.filter(item => item.name.toUpperCase().includes(name.toUpperCase()));
    } else {
      this.leftList = this.leftListBackup;
    }
  }

  filterRightList(name?: string) {
    if (name) {
      this.rightList = this.rightListBackup.filter(item => item.name.toUpperCase().includes(name.toUpperCase()));
    } else {
      this.rightList = this.rightListBackup;
    }
  }

  sortLeftList(initial?: boolean) {
    if (!initial) {
      this.leftList = this.leftListBackup;
    }
    this.leftList = sortBy(this.leftList, 'name')
  }

  sortRightList(mustSort?: boolean, initial?: boolean) {
    if (!initial) {
      this.rightList = this.rightListBackup;
    }
    const right = clone(this.rightList);
    this.rightList = [];
    right.forEach((item, index) => {
      const leftItem = find(this.originalLeftList, { id: item[this.secondaryAttrName] })
      this.rightList.push({
        ...item,
        name: leftItem?.name,
        description: leftItem?.description,
        index: index
      });
    })
    if (mustSort) {
      this.rightList = sortBy(this.rightList, this.useOrder ? 'order' : 'name')
    }
    this.rightListBackup = this.rightList;
  }

  getFilteredLeftList() {
    return this.originalLeftList
      .filter(a => this.useOrder || !this.rightList.map(b => b[this.secondaryAttrName]).includes(a.id))
      .map(item => {
        item[this.secondaryAttrName] = item.id
        return item
      });
  }

  drop(event: CdkDragDrop<string[]>, side: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else if (!this.useOrder) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex)
      if (side === 'left') {
        this.sortLeftList();
      } else if (side === 'right') {
        this.filterRightList();
        this.sortRightList();
      }
    } else {
      if (side === 'left') {
        this.sortLeftList();
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex)
      } else if (side === 'right') {
        const currentIndex = this.rightList.length < this.rightListBackup.length ? 0 : event.currentIndex;
        this.filterRight.reset();
        this.filterRightList();
        copyArrayItem(event.previousContainer.data,
          this.rightList,
          event.previousIndex,
          currentIndex)
        this.sortRightList();
      }
    }
  }

  editOrderItem(item: any) {
    if (this.useOrder) {
      if (this.rightEntity === 'JourneyStep') {
        const dialogConfig = new MatDialogConfig()
        dialogConfig.data = { ...item, ...{ _view: this.disabled } };
        const dialog = this.dialog.open(StepParamsDialogComponent, dialogConfig)
        dialog.afterClosed().subscribe(async data => {
          if (data) {
            item.order = data.order
            item.parameters = data?.parameters?.filter(param => param.name && typeof param.value !== 'undefined')
          }
        })
      } else {
        item.order = parseInt(window.prompt(this.translateService.transform('trl_sequence'), item.order)) || item.order;
      }
    }
  }

  checkOrder() {
    if (this.useOrder) {
      this.sortRightList(true)
      for (let i = 0; i < this.rightList.length; i++) {
        if (
          typeof this.rightList[i].order === 'undefined'
          || this.rightList[i].order === null
          || i === 0 && this.rightList[i].order !== 1
          || this.rightList[i - 1] && this.rightList[i - 1].order !== this.rightList[i].order &&
          this.rightList[i - 1].order !== this.rightList[i].order - 1
        ) {
          throw new Error(this.translateService.transform('trl_sequence_error'))
        }
      }
    }
  }

  async saveAll(mainAttrValue: any) {
    try {
      this.checkOrder();
      const service = new BaseService(this.restangular);
      service.setEntity(this.rightEntity);

      await this.removeItems(service);

      if (this.useTenant && this.defaultAttr) {
        const tenantId = window.sessionStorage.getItem('tenantId')
        this.defaultAttr.tenantId = tenantId
      }

      const newItems = this.getNewItems(mainAttrValue);

      for (const item of newItems) {
        const newItem = await service.create(item)
        if (item.parameters) {
          for (const parameter of item.parameters) {
            const serviceStepParams = new BaseService(this.restangular)
            serviceStepParams.setEntity('StepParams')
            await serviceStepParams.create({
              name: parameter.name,
              value: parameter.value,
              journeyStepId: newItem.id
            })
          }
        }
      }
      this.saveSuccess()
    } catch (error) {
      this.saveError(error?.message)
      throw error
    }
  }

  async removeItems(service: any) {
    for (const item of this.originalRightList) {
      if (item.parameters) {
        for (const parameter of item.parameters) {
          if (parameter.id) {
            const serviceStepParams = new BaseService(this.restangular)
            serviceStepParams.setEntity('StepParams')
            await serviceStepParams.delete(parameter.id)
          }
        }
      }
      await service.delete(item.id)
    }
  }

  getNewItems(mainAttrValue: any) {
    return this.rightListBackup.map((item, index) => ({
      order: this.useOrder ? item.order : undefined,
      parameters: this.useOrder ? item.parameters : undefined,
      [this.mainAttrName]: this.mainAttrValue || mainAttrValue,
      [this.secondaryAttrName]: item[this.secondaryAttrName],
      ...this.defaultAttr
    }));
  }

  showNotification(msg, panelClass: 'success' | 'error' | 'info' = 'info') {
    this.snackBar.open(msg, 'OK', {
      duration: NOTIFICATION_TIME,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: `notification-${panelClass}`
    })
  }

  saveSuccess() {
    this.showNotification(this.messagesService.trl_saved_successfully, 'success')
  }

  saveError(errorMsg?: string) {
    this.showNotification(errorMsg || this.messagesService.trl_error_when_trying_to_save, 'error')
  }

}
