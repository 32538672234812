import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Restangular } from 'ngx-restangular';
import { catchError, switchMap } from 'rxjs/operators';
import { BaseService } from './basic.service';
import { LayoutService } from './layout.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  public menuIndicatorsUser: any;
  public refreshingToken: boolean;
  public requestsPendingRefreshToken = [];
  urlBaseAuthApi = '/Api';

  constructor(
    public restangular: Restangular,
    private tenantService: TenantService,
    private router: Router,
    private cookieService: CookieService,
    private layoutService: LayoutService,
    private httpBackend: HttpClient
  ) {
    super(restangular)
    this.setEntity('User');
  }

  async findUserData() {
    const userEmail = window.localStorage.getItem('userEmail');
    if (userEmail) {
      let userData: any;
      if (localStorage.getItem('userData')) {
        userData = JSON.parse(localStorage.getItem('userData'));
      } else if (sessionStorage.getItem('userData')) {
        userData = JSON.parse(sessionStorage.getItem('userData'));
      } else {
        userData = await this.getAccess(userEmail);
      }
      userData.module.forEach(m => {
        m.operation = this.resolveReferences(m.operation);
      });
      if (!userData?.id) {
        console.log('Error on accessing user data...')
      } else {
        window.sessionStorage.setItem('tenantId', userData?.tenantId)
      }
      return userData;
    } else {
      console.log('User not authenticated...');
    }
  }

  getUserData() {
    if (localStorage.getItem('userData')) {
      return JSON.parse(localStorage.getItem('userData'));
    }
    if (sessionStorage.getItem('userData')) {
      return JSON.parse(sessionStorage.getItem('userData'));
    }
    return false;
  }

  async setUserData(userData: any, stayLogged?: boolean) {
    this.getUserPermissions();
    localStorage.setItem('token', userData.access_token);
    localStorage.setItem('refreshToken', userData.refresh_token);
    if (userData.customerId) {
      window.localStorage.setItem('userCustomerId', userData.customerId);
    }
    localStorage.removeItem('userData');
    if (stayLogged) {
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      sessionStorage.setItem('userData', JSON.stringify(userData));
    }
  }

  resolveReferences(json: any) {
    /* eslint-disable */
    if (typeof json === 'string')
      json = JSON.parse(json);

    let byid = {};
    let refs = [];
    json = (function recurse(obj, prop, parent) {
      if (typeof obj !== 'object' || !obj)
        return obj;
      if (Object.prototype.toString.call(obj) === '[object Array]') {
        for (let i = 0; i < obj.length; i++)
          if (typeof obj[i] !== 'object' || !obj[i])
            continue;
          else if ("$ref" in obj[i])
            obj[i] = recurse(obj[i], i, obj);
          else
            obj[i] = recurse(obj[i], prop, obj);
        return obj;
      }
      if ("$ref" in obj) {
        const ref = obj.$ref;
        if (ref in byid)
          return byid[ref];
        refs.push([parent, prop, ref]);
        return;
      } else if ("$id" in obj) {
        const id = obj.$id;
        delete obj.$id;
        if ("$values" in obj)
          obj = obj.$values.map(recurse);
        else
          for (prop in obj)
            obj[prop] = recurse(obj[prop], prop, obj);
        byid[id] = obj;
      }
      return obj;
    })(json);
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i];
      ref[0][ref[1]] = byid[ref[2]];
    }
    return json;
  }

  getAccess(id: string) {
    return this.provider.one(`/${this.entity}/${id}/access`)
      .get().toPromise()
  }

  signOut() {
    const userViewOnboarding = localStorage.getItem('viewOnboarding');
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    if (userViewOnboarding) {
      localStorage.setItem('viewOnboarding', userViewOnboarding);
    }
    const cliente = this.tenantService.getCliente();
    this.router.navigate([`${cliente.name}/${cliente.homePage}`]);
    this.layoutService.updateMenu();
  }

  async getUserPermissions(): Promise<any[]> {
    if (localStorage.getItem('userPermissions')) {
      this.getUserPermissionsAsync()
      return JSON.parse(localStorage.getItem('userPermissions'))
    }
    return this.getUserPermissionsAsync()
  }

  async getUserPermissionsAsync(): Promise<any[]> {
    let { module: userPermissions = [] } = await this.findUserData();
    userPermissions = userPermissions
      .flatMap((m: any) => m.operation)
      .map(
        (operation: any) => '/' + this.tenantService.getOrgId() + operation.path
      )
    localStorage.setItem('userPermissions', JSON.stringify(userPermissions))
    return userPermissions
  }

  getUserPermissionsFromCache() {
    if (window.localStorage.getItem('userPermissions')) {
      return JSON.parse(window.localStorage.getItem('userPermissions'))
    }
    return []
  }

  getUserEmail(): string {
    return window.localStorage.getItem('userEmail');
  }

  hasPermission(key: string): boolean {
    const permissions = this.getUserPermissionsFromCache()
    return permissions.findIndex((permission: any) => permission.includes(key)) >= 0
  }

  hasPermissionToTab(entity: string, url?: string, dontType?: boolean): boolean {
    return this.hasPermissionToAction(entity, dontType ? null : 'view', url)
  }

  hasPermissionToAction(entity: string = '', action: string = '', url?: string): boolean {
    const currentUrl = this.router.url.split('/');
    return this.hasPermission(
      `${url ? url : currentUrl[currentUrl.length - 1]}|${entity.toLocaleLowerCase()}${action ? '|' + action.toLocaleLowerCase() : ''}`
    )
  }

  refreshAccesstoken() {
    const user = this.getUserData();
    const params = { RefreshToken: user.refresh_token };
    this.refreshingToken = true;
    return this.httpBackend.get(
      `${this.urlBaseAuthApi}/PermissionManagement/user/refreshtoken`, { params })
      .pipe(
        switchMap((token: any) => {
          this.refreshingToken = null;
          user.access_token = token.access_token;
          this.setUserData(user);
          this.repeatPendingRequests();
          return token.access_token;
        }),
        catchError((err: any) => {
          this.refreshingToken = null;
          return null;
        }));
  }

  repeatPendingRequests() {
    this.requestsPendingRefreshToken.forEach(pr => {
      const { response, subject, responseHandler } = pr;
      const newHeaders = new HttpHeaders({ Authorization: `Bearer ${localStorage.getItem('token')}` });
      const newReq = response.request.clone({ headers: newHeaders });
      response.repeatRequest(newReq).subscribe(res => responseHandler(res), err => subject.error(err));
    });
    this.requestsPendingRefreshToken = [];
  }

}
