import { Injectable } from '@angular/core'
import { BaseService } from './basic.service'
import { Restangular } from 'ngx-restangular'

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseService {

  constructor(public restangular: Restangular) {
    super(restangular)
    this.setEntity('Group')
  }

  getAccess(id: string) {
    return this.provider.one(`/${this.entity}/${id}/access`)
      .get().toPromise()
  }

}
