import { Component, OnDestroy, OnInit } from '@angular/core'
import { Cliente } from 'app/legacy/core/model/tenant/cliente'
import { LayoutService } from 'app/legacy/core/services/layout.service'
import { TenantService } from 'app/legacy/core/services/tenant.service'
import { UserService } from 'app/legacy/core/services/user.service'
import { Subscription } from 'rxjs'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.scss']
})
export class UserOnboardingComponent implements OnInit, OnDestroy {
  userLogged: any
  public showOnboarding = false
  public client: Cliente
  public activeStep = 1
  private subscription: Subscription

  constructor(
    private tenantService: TenantService,
    private userService: UserService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.client = this.tenantService.getCliente()
    this.checkShowOnboarding()
    this.subscription = this.layoutService.showOnboarding$.subscribe(() => {
      this.checkShowOnboarding()
    })
  }

  checkShowOnboarding() {
    if (this.client.showOnboarding) {
      this.userLogged = this.userService.getUserData()
      const onboardingLocal = JSON.parse(localStorage.getItem('viewOnboarding'))
      if (this.userLogged && (!onboardingLocal || onboardingLocal.userId !== this.userLogged.id)) {
        this.activeStep = 1
        this.showOnboarding = true
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  nextStep() {
    this.activeStep += 1
  }

  previousStep() {
    this.activeStep -= 1
  }

  finishOnboarding() {
    const objectUser = {
      userId: this.userLogged.id,
      viewOnboarding: true
    }
    localStorage.setItem('viewOnboarding', JSON.stringify(objectUser))
    this.showOnboarding = false
  }
}
