import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Input() clickAction: string;
  @Output() public clickOutside = new EventEmitter();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onclick(element: any) {
    const clickInside = this._elementRef.nativeElement.contains(element);
    const buttonClick = element.getAttribute('name') === this.clickAction;
    if (!clickInside && !buttonClick) {
      this.clickOutside.emit(null);
    }
  }
}
