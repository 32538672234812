import { memoize, randomIntFromInterval } from 'app/utils/utils'

const baseColorsPallete: string[] = ['#ec672c', '#006241', '#f9b24d', '#b7caea']

const hexToRgb = (color: string): number[] => {
  const hex = color.replace(/^#/, '').toUpperCase()

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return [r, g, b]
}

const hexListToRgbList = (hexList: string[]): number[][] => hexList.map(hexToRgb)

const getRandomColor = () => [
  randomIntFromInterval(0, 255),
  randomIntFromInterval(0, 255),
  randomIntFromInterval(0, 255)
]

export const rgba = (color: number[], alpha = 1) => `rgba(${color.concat(alpha).join(',')})`

export const chooseColor = memoize(
  (index: number = 0) => hexListToRgbList(baseColorsPallete)[index] || getRandomColor()
)
