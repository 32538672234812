import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from 'app/legacy/core/model/tenant/cliente';
import { BaseService } from 'app/legacy/core/services/basic.service';
import { LayoutService } from 'app/legacy/core/services/layout.service';
import { TenantService } from 'app/legacy/core/services/tenant.service';
import { UserService } from 'app/legacy/core/services/user.service';
import { Restangular } from 'ngx-restangular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {

  @Input() menuItems: any;
  menuIndicators: any;
  client: Cliente;
  clientUrl: string;
  statusActiveMenu: any;
  userData: any;

  subscriptions: Subscription;
  indexMenuOpened: any;
  menuIndicatorsOpened = false;
  filteredMenuItems = [];
  menuService = new BaseService(this.restangular);
  statusService = new BaseService(this.restangular);

  constructor(
    private restangular: Restangular,
    private tenantService: TenantService,
    private userService: UserService,
    private layoutService: LayoutService,
    private router: Router) { }

  async ngOnInit() {
    await this.findMenus();
    this.subscriptions = this.layoutService.updateMenu$.subscribe(() => this.findMenus());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async findMenus() {
    this.menuService.setEntity('Menu')
    await this.getStatusMenu();
    this.client = this.tenantService.getCliente();
    this.clientUrl = this.tenantService.getClienteUrl();
    this.userData = await this.userService.getUserData();
    this.filteredMenuItems = await this.getMenuItems();
    await this.getDashboardMenu();
  }

  async getMenuItems() {
    if (this.userData?.id) {
      const permissions = await this.userService.getUserPermissions();
      return this.menuItems?.filter(menuItem => {
        if (menuItem.public || !menuItem.path) {
          return true
        }
        return permissions.findIndex(permission => menuItem.path.includes(permission)) >= 0
      }).map(menuItem => {
        if (menuItem.subMenuItems) {
          menuItem.subMenuItems = menuItem.subMenuItems.filter(
            subMenuItem => subMenuItem.public || permissions.findIndex(permission => subMenuItem.path.includes(permission)) >= 0
          );
        }
        return menuItem;
      }).filter(menuItem => !menuItem.subMenuItems || menuItem.subMenuItems?.length > 0);
    } else {
      return this.menuItems.filter(menu => menu.public);
    }
  }

  async getStatusMenu() {
    this.statusService.setEntity('Status');
    this.statusActiveMenu = await this.statusService.findAll();
    this.statusActiveMenu = this.statusActiveMenu.results.filter((status: any) => status.name === 'ATIVO')[0];
  }

  handleClickMenu(menu: any, index: number) {
    this.menuIndicatorsOpened = false;
    if (this.indexMenuOpened === index) {
      this.indexMenuOpened = null;
    } else {
      if (menu.subMenuItems?.length) {
        this.indexMenuOpened = index;
      } else {
        this.router.navigateByUrl(menu.path);
        this.indexMenuOpened = null;
      }
    }
  }

  handleClickMenuIndicators() {
    this.indexMenuOpened = null;
    this.menuIndicatorsOpened = !this.menuIndicatorsOpened;
  }

  closeSubMenu() {
    this.indexMenuOpened = null;
    this.menuIndicatorsOpened = false;
  }

  checkSubmenuActive(submenu: any) {
    return this.router.url === submenu.path;
  }

  checkMenuActive(menu: any) {
    if (menu.subMenuItems?.length) {
      return menu.subMenuItems.find(sm => sm.path === this.router.url);
    }
    return this.router.url === menu.path;
  }

  async getDashboardMenu() {
    const userCustomerId = localStorage.getItem('userCustomerId');
    let params = { skip: 0, take: 10000 };
    if (userCustomerId) {
      params = { ...params, ...{ filterProp: 'customerId', filterValue: userCustomerId } }
    }
    const menuDashboard = await this.menuService.findAll(params);
    if (userCustomerId) {
      menuDashboard.results = menuDashboard?.results?.filter(m => m.customerId === userCustomerId);
    }
    let customMenu = menuDashboard.results.filter((menu: any) => menu.statusId === this.statusActiveMenu.id);
    customMenu = customMenu.map((item: any) => {
      if (item.public) {
        return {
          title: item.name,
          id: `dashboard-${item.id}`,
          subMenuItems: item.subMenu
        }
      } else if (this.hasPermission(item.operationId)) {
        const menu = {
          title: item.name,
          id: `dashboard-${item.id}`,
          subMenuItems: this.checkPermissionsSubMenuAndTab(item.subMenu)
        }
        return menu.subMenuItems.length ? menu : null;
      }
      return null;
    }).filter(m => m);
    customMenu.forEach(m => m.subMenuItems = m.subMenuItems?.sort((sm1, sm2) => sm1.ordem - sm2.ordem));
    this.menuIndicators = customMenu;
    this.handleMenuIndicators();
  }

  handleMenuIndicators() {
    const subMenus = [];
    this.menuIndicators.forEach(menu => {
      if (menu.subMenuItems?.length) {
        menu.subMenuItems.map(sm => subMenus.push(sm));
      }
    });
    this.menuIndicators = subMenus;
    const menuIndicators = this.filteredMenuItems.findIndex(m => m.title === 'trl_indicators');
    if (menuIndicators) {
      this.filteredMenuItems[menuIndicators] = {
        ...this.filteredMenuItems[menuIndicators],
        ...{ subMenuItems: this.menuIndicators }
      };
    }
    this.userService.menuIndicatorsUser = this.menuIndicators;
  }

  checkPermissionsSubMenuAndTab(subMenu: any) {
    const items = [];
    subMenu.forEach((sub: any) => {
      if (sub.operationId && this.userData?.module.some(m => m?.operation.some(o => o?.id === sub.operationId))) {
        sub.tab = sub.tab.map(t => {
          if (t.operationId && this.userData?.module.some(m => m?.operation.some(o => o?.id === t.operationId))) {
            return t;
          }
        })
        items.push(sub)
      }
    });
    return items;
  }

  hasPermission(menuOperationId) {
    if (menuOperationId && this.userData) {
      return this.userData.module.some(m => m?.operation.some(o => o?.id === menuOperationId))
    }
    return false;
  }

  goToDashboard(dashboard) {
    const dashboardActive = localStorage.getItem('tabsDashboardActive');
    if (dashboardActive) {
      localStorage.removeItem('tabsDashboardActive');
    }
    if (dashboard) {
      const tabsData = JSON.stringify(dashboard.tab);
      localStorage.setItem('tabsDashboardActive', tabsData);
      this.router.navigate([`/${this.clientUrl}/custom-menu-content/${dashboard.id}`]);
    }
  }

}
