import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core'

import { ChartConfiguration, ChartType, DefaultDataPoint, Plugin, UpdateMode } from 'chart.js'
import { Chart } from './config/chartjs-config'
import { ChartHeightValue } from './config/utils'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent<TType extends ChartType = ChartType, TData = DefaultDataPoint<TType>, TLabel = unknown>
  implements AfterViewInit, OnDestroy, OnChanges
{
  @HostBinding('class') class = 'tw-contents'
  @ViewChild('chartCanvas') canvas!: ElementRef<HTMLCanvasElement>

  @Input() public containerHeight?: ChartHeightValue

  @Input() public type: ChartConfiguration<TType, TData, TLabel>['type'] = 'bar' as TType
  @Input() public data!: ChartConfiguration<TType, TData, TLabel>['data']
  @Input() public options: ChartConfiguration<TType, TData, TLabel>['options']
  @Input() public plugins: Plugin<TType>[] = []

  chart?: Chart<TType, TData, TLabel>

  ngOnChanges(changes: SimpleChanges): void {
    this.update()
  }

  ngAfterViewInit() {
    this.destroy()
    this.render()
  }

  ngOnDestroy(): void {
    this.destroy()
  }

  render() {
    this.chart = new Chart(this.canvas.nativeElement, this.getChartConfig())
  }

  update(mode?: UpdateMode) {
    if (this.chart) {
      this.chart.update(mode)
    }
  }

  destroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

  getChartConfig(): ChartConfiguration<TType, TData, TLabel> {
    return {
      type: this.type,
      data: this.data,
      options: this.options,
      plugins: this.plugins
    }
  }
}
