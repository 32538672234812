import { TenantService } from '../tenant.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class RedirectIfBrt implements CanActivate {

    constructor(private router: Router, private tenantService: TenantService) {
    }

     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const client = this.tenantService.getCliente();
        if (client.name === 'who') {
            this.router.navigate([`/${client.name}/${client.homePage}`]);
            return false;
        }

        return true;
    }
}
