// TODO: Implementar as chamadas reais e remover underscore do import
import { Metric, MetricType } from 'app/models/types/metric'
import { delay, randomIntFromInterval } from 'app/utils/utils'
import { eachDayOfInterval, format } from 'date-fns'
import _api from './api'

const api = _api

export const getMockedMetricData = async (
  productId: string,
  metricType: MetricType,
  startPeriod: Date,
  endPeriod: Date
) => {
  /**
   * TODO: Fazer chamada real
  const response = await api.get<Metric>('ApiFraudCenter/metrics', {
    params: { productId, metricType, startPeriod, endPeriod }
  })
  */

  await delay(5000)

  // TODO: Obter resposta real
  // const metric = response.data

  const allDates = eachDayOfInterval({ start: startPeriod, end: endPeriod })

  const metric: Metric = {
    clientId: '',
    clientProductId: productId,
    type: metricType,
    data: allDates.map((date) => ({
      referenceDate: format(date, 'dd/MM/yyyy'),
      total: randomIntFromInterval(0, 600)
    }))
  }

  return metric
}
