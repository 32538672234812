import { Component } from '@angular/core';

@Component({
    selector: 'app-hide-sidebar',
    templateUrl: './hide-sidebar.component.html',
    styleUrls: ['./hide-sidebar.component.css']
})
export class HideSidebarComponent {

    sidebarToggle2() {
        if (document.body.classList.contains('sidebar-mini')) {
            document.body.classList.remove('sidebar-mini')
        } else {
            document.body.classList.toggle('sidebar-mini')
        }
    }

    get isSidebarOpen() {
        return document.body.classList.contains('sidebar-mini')
    }

}
