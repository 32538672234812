import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar'

const NOTIFICATION_TIME = 3000

@Injectable()
export class MessagesService {

    errorName: string;
    trl_saved_successfully: string;
    trl_error_when_trying_to_save: string;
    trl_successfully_removed: string;
    trl_error_removing: string;
    trl_are_you_sure_you_want_to_remove: string;
    trl_alread_exist_email: string;
    trl_alread_exist_cpf: string;
    trl_alread_exist_cnpj: string;
    trl_verify_field: string;
    trl_action_forbidden: string;

    constructor(private translateService: TranslateService, private snackBar: MatSnackBar) {

        this.translateService.onLangChange.subscribe((e: Event) => {
            this.translateMessages();
        });

        this.translateMessages();
    }

    async translateMessages() {
        const objTrl = await this.translateService.get([
            'trl_saved_successfully',
            'trl_error_when_trying_to_save',
            'trl_successfully_removed',
            'trl_error_removing',
            'trl_are_you_sure_you_want_to_remove',
            'trl_alread_exist_email',
            'trl_alread_exist_cpf',
            'trl_alread_exist_cnpj',
            'trl_verify_field',
            'trl_action_forbidden'
        ]).toPromise();
        this.trl_saved_successfully = objTrl.trl_saved_successfully;
        this.trl_error_when_trying_to_save = objTrl.trl_error_when_trying_to_save;
        this.trl_successfully_removed = objTrl.trl_successfully_removed;
        this.trl_error_removing = objTrl.trl_error_removing;
        this.trl_are_you_sure_you_want_to_remove = objTrl.trl_are_you_sure_you_want_to_remove;

        this.trl_alread_exist_email = objTrl.trl_alread_exist_email;
        this.trl_alread_exist_cpf = objTrl.trl_alread_exist_cpf;
        this.trl_alread_exist_cnpj = objTrl.trl_alread_exist_cnpj;
        this.trl_verify_field = objTrl.trl_verify_field;
        this.trl_action_forbidden = objTrl.trl_action_forbidden;
    }

    notification(error) {
        if (error?.data?.code === 401 || error?.status === 401) {
            return this.trl_action_forbidden
        } else if (error?.data?.code === 409 || error?.status === 409) {
            return error?.data?.message?.includes('CPF already exists') ?
                this.trl_alread_exist_cpf : this.trl_alread_exist_email;
        } else {
            return this.trl_verify_field
        }
    }

    public showNotification(msg, panelClass: 'success' | 'error' | 'info' = 'info') {
        this.snackBar.open(msg, 'OK', {
            duration: NOTIFICATION_TIME,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: `notification-${panelClass}`
        })
    }

}
