import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
/* ANGULAR MODULES */
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
/* END ANGULAR MODULES */
/* VENDOR MODULES*/
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ngfModule } from 'angular-file';
import { LoginService } from 'app/legacy/auth/services/userLogin.service';
import { AuthGuard } from 'app/legacy/components/auth/auth.guard';
import { UserCustomerGuard } from 'app/legacy/components/auth/user-customer.guard';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { TreeviewModule } from 'ngx-treeview';
/* END CORE SERVICES */
/* CORE COMPONENTS */
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ClientLogoComponent } from './components/client-logo/client-logo.component';
import { CNPJPipe, CrudListContainerComponent } from './components/crud-list-container/crud-list-container.component';
import { MatPaginatorI18nService } from './components/crud-list-container/translate-paginator-intl';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { FooterComponent } from './components/footer/footer.component';
import { HideSidebarComponent } from './components/hide-sidebar/hide-sidebar.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PermissionsSelectComponent } from './components/permissions-select/permissions-select.component';
import { PriceRangeComponent } from './components/price-range/price-range.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SmartappIntegrationComponent } from './components/smartapp-integration/smartapp-integration.component';
import { StepParamsDialogComponent } from './components/step-params-dialog/step-params-dialog.component';
/* END VENDOR MODULES*/
/* CORE SERVICES */
import { BaseService } from './services/basic.service';
import { MessagesService } from './services/messages.service';
import { SweetAlertService } from './services/sweet-alert.service';
import { TranslationModule } from './translation/translation.module';
import { SafePipe } from '../reports/report/app-report.component';


/* END CORE COMPONENTS */

@NgModule({
  declarations: [
    SafePipe,
    CNPJPipe,
    AutocompleteComponent,
    CrudListContainerComponent,
    DragAndDropComponent,
    FooterComponent,
    HideSidebarComponent,
    IframeComponent,
    NavbarComponent,
    PermissionsSelectComponent,
    SidebarComponent,
    SmartappIntegrationComponent,
    ClientLogoComponent,
    StepParamsDialogComponent,
    PriceRangeComponent
  ],
  imports: [
    CommonModule,
    NgImageSliderModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ngfModule,
    NgxPowerBiModule,
    NgJsonEditorModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule, MatSortModule, MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatStepperModule,
    MatSlideToggleModule,
    DragDropModule,
    MatCardModule,
    RouterModule,
    TranslationModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    TreeviewModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forChild({
      extend: true
    })
  ],
  exports: [
    NgImageSliderModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ngfModule,
    SweetAlert2Module,
    NgxPowerBiModule,
    NgJsonEditorModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule, MatSortModule, MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatStepperModule,
    MatSlideToggleModule,
    DragDropModule,
    MatCardModule,
    TreeviewModule,
    NgxMaskModule,
    SafePipe,
    CNPJPipe,
    AutocompleteComponent,
    CrudListContainerComponent,
    DragAndDropComponent,
    FooterComponent,
    HideSidebarComponent,
    IframeComponent,
    NavbarComponent,
    PermissionsSelectComponent,
    SidebarComponent,
    SmartappIntegrationComponent,
    ClientLogoComponent,
    PriceRangeComponent,
    TranslationModule,
    NgSelectModule
  ],
  providers: [
    BaseService,
    MessagesService,
    SweetAlertService,
    LoginService,
    AuthGuard,
    UserCustomerGuard,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService
    }
  ]
})
export class CoreModule {
  constructor(private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale(translateService.currentLang)
  }
}
