import { Injectable } from '@angular/core'
import { isUndefined } from 'lodash'
import { Restangular } from 'ngx-restangular'

interface IQueryParams {
    operatorEmail?: string
    take?: number
    skip?: number
    data?: string;
}

@Injectable()
export class BaseService {

    public disabled: boolean
    public entity = ''
    public baseUrl = `${window.location.origin}/Api`
    public apiType: 'PermissionManagement' | 'ContractRegistration' | 'ServiceRequest' | 'Transaction/v1' | 'DataCollector' | 'ServiceIntegration' | 'Flow/v1' | 'apiFraudCenter/v1'
    public headers: any = {}

    constructor(public restangular: Restangular) { }

    get provider() {
        const tenantId = window.sessionStorage.getItem('tenantId') || ''
        return this.restangular.withConfig((RestangularProvider) => {
            if (!this.apiType) {
                this.setApiType(this.getApiType(this.entity))
            }

            RestangularProvider.setBaseUrl(`${this.baseUrl}/${this.apiType}`)
            RestangularProvider.setDefaultHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                tenantId,
                ...this.headers
            })
        })
    }

    get providerFile() {
        const tenantId = window.sessionStorage.getItem('tenantId') || ''
        return this.restangular.withConfig((RestangularProvider) => {
            if (!this.apiType) {
                this.setApiType(this.getApiType(this.entity))
            }
            RestangularProvider.setBaseUrl(`${this.baseUrl}/${this.apiType}`)
            RestangularProvider.setDefaultHeaders({
                tenantId,
                ...this.headers
            })
        })
    }

    findAll(params?: IQueryParams | any) {
        const tenantId = window.sessionStorage.getItem('tenantId')
        return this.provider.one(`/${this.entity}?${this.serializeParams({ ...params, tenantId })}`)
            .get().toPromise()
    }

    findAllPath(params?) {
        const pathParams = Object.keys(params).map((key) => [key, params[key]]).map(cv => cv.join('/')).join('/');
        return this.provider.one(`/${this.entity}/find-all/${pathParams}`)
            .get().toPromise()
    }

    getOneFilter(params?: IQueryParams | any) {
        return this.provider.one(`/${this.entity}user?${this.serializeParams({ ...params })}`)
            .get().toPromise()
    }

    getOne(id: number) {
        return this.provider.one(`/${this.entity}/${id}`)
            .get().toPromise()
    }

    create(data: any) {
        data.createdBy = this.currentUserEmail
        return this.provider.one(`/${this.entity}`)
            .customPOST(data).toPromise()
    }

    createWithFormData(formData: any) {
        formData.createdBy = this.currentUserEmail
        return this.providerFile.one(`/${this.entity}`)
            .customPOST(formData).toPromise()
    }

    update(data: any) {
        return this.provider.one(`/${this.entity}/${data?.id}`)
            .customPUT(data).toPromise()
    }

    upsert(data: any) {
        if (data?.id) {
            return this.update(data)
        }
        return this.create(data)
    }

    updateFile(data: any, id: any) {
        return this.providerFile.one(`/${this.entity}/${id}`)
            .customPUT(data).toPromise()
    }

    delete(id: number) {
        return this.provider.all(`/${this.entity}/${id}`)
            .remove().toPromise()
    }

    setEntity(entity: string) {
        this.entity = entity
    }

    setHeaders(headers: any = {}) {
        this.headers = headers
    }

    public setDisabled(value: any) {
        window.localStorage.setItem('disabled', value)
    }

    setApiType(type: 'PermissionManagement' | 'ContractRegistration' | 'ServiceRequest' | 'Transaction/v1' | 'DataCollector' | 'ServiceIntegration' | 'Flow/v1' | 'apiFraudCenter/v1') {
        this.apiType = type
    }

    get currentUserEmail() {
        return window.localStorage.getItem('userEmail')
    }

    isPermissionEntity(entity: string) {
        switch (entity) {
            case 'User':
            case 'UserParameter':
            case 'Parameter':
            case 'TypeParameter':
            case 'Group':
            case 'UserGroup':
            case 'Operation':
            case 'OperationUser':
            case 'OperationGroup':
            case 'Module':
            case 'System':
                return true
            default:
                return false
        }
    }

    getApiType(entity: string, parentEntity?: string) {
        switch (entity) {
            case 'User':
            case 'UserParameter':
            case 'Parameter':
            case 'TypeParameter':
            case 'Group':
            case 'UserGroup':
            case 'Operation':
            case 'OperationUser':
            case 'OperationGroup':
            case 'Module':
            case 'System':
                return 'PermissionManagement'
            case 'transaction':
                return 'apiFraudCenter/v1'
            case 'ServiceRequest':
            case 'request':
                return 'ServiceRequest'
            case 'ServiceIntegration':
                return 'ServiceIntegration'
            case 'Status':
                if (parentEntity === 'User' || parentEntity === 'Group') {
                    return 'PermissionManagement'
                }
                return 'ContractRegistration'
            case 'collect':
            case 'experimentation':
                return 'DataCollector'
            case 'flow/enumValues':
                return 'apiFraudCenter/v1'
            default:
                return 'ContractRegistration'
        }
    }

    serializeParams(obj) {
        const str = []
        for (const p in obj) {
            if (obj.hasOwnProperty(p) && !isUndefined(obj[p]) && obj[p] !== 'undefined') {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
        }
        return str.join('&')
    }

}
