import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from './../services/tenant.service';

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private tenantService: TenantService) { }

  transform(key: string, params?: Object): any {
    if (!key) {
      return;
    }
    const language = this.translateService.getDefaultLang();
    const translations = this.tenantService && this.tenantService.translateKeys ? this.tenantService.translateKeys : null;
    const currentLanguageTranslations = translations && this.tenantService.translateKeys[language] ?
      this.tenantService.translateKeys[language] : null;

    return currentLanguageTranslations && currentLanguageTranslations[key] !== undefined ?
      currentLanguageTranslations[key] : this.translateService.instant(key, params);
  }

}
