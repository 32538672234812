import { Component, OnInit } from '@angular/core';
import { Cliente } from 'app/legacy/core/model/tenant/cliente';
import { TenantService } from 'app/legacy/core/services/tenant.service';

@Component({
  selector: 'app-menu-dropdown-apps',
  templateUrl: './menu-dropdown-apps.component.html',
  styleUrls: ['./menu-dropdown-apps.component.scss']
})
export class MenuDropdownAppsComponent implements OnInit {

  public menuAppsOpened = false;
  public client: Cliente;

  constructor(
    private tenantService: TenantService
  ) { }

  ngOnInit(): void {
    this.client = this.tenantService.getCliente();
  }

  closeMenuApps() {
    this.menuAppsOpened = false;
  }

}
