import { TenantService } from './../../core/services/tenant.service';
import { Cliente } from './../../core/model/tenant/cliente';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LayoutService } from 'app/legacy/core/services/layout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {

  public client: Cliente;
  public userData: any;
  public sidebarCollapsed = false;
  public isMobile: MediaQueryList;
  private mobile_menu_visible: any = 0
  private sidebarVisible = false;
  private subscriptions: Subscription

  constructor(
    private tenantService: TenantService,
    private layoutService: LayoutService,
    private mediaMatcher: MediaMatcher
  ) {
    this.isMobile = this.mediaMatcher.matchMedia('(max-width: 980px)');
  }

  ngOnInit(): void {
    this.client = this.tenantService.getCliente();
    this.getUserData();
    this.subscriptions = this.layoutService.updateMenu$.subscribe(() => {
      this.getUserData();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getUserData() {
    this.userData = localStorage.getItem('userData') ?
      JSON.parse(localStorage.getItem('userData')) : null;
  }

  checkSidebarToggle() {
    if (this.isMobile.matches) {
      this.sidebarMobileToggle();
    } else {
      this.sidebarToggle();
    }
  }

  sidebarToggle() {
    if (document.body.classList.contains('sidebar-mini')) {
      document.body.classList.remove('sidebar-mini');
      this.sidebarCollapsed = false;
    } else {
      document.body.classList.toggle('sidebar-mini');
      this.sidebarCollapsed = true;
    }
  }

  sidebarMobileToggle() {
    const body = document.getElementsByTagName('body')[0];

    if (!this.sidebarVisible) {
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      body.classList.remove('nav-open');
      this.sidebarVisible = false;
    }

    if (this.mobile_menu_visible === 1) {
      body.classList.remove('nav-open');
      const $layer = document.querySelectorAll('.close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
      this.mobile_menu_visible = 0
    } else {
      const $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');
      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }
      setTimeout(function () {
        $layer.classList.add('visible')
      }, 100)
      $layer.onclick = function () {
        body.classList.remove('nav-open')
        this.mobile_menu_visible = 0
        $layer.classList.remove('visible')
        setTimeout(function () {
          $layer.remove();
        }, 400)
      }.bind(this)
      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  }

}
