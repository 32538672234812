import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { BaseService } from 'app/legacy/core/services/basic.service'
import { startWith, map } from 'rxjs/operators'
import { Restangular } from 'ngx-restangular'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { isUndefined } from 'lodash'

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatAutocompleteTrigger) trigger: any;

  @Output() onLoad: EventEmitter<any> = new EventEmitter();
  @Output() onDataChange = new EventEmitter();
  @Output() dataChange = new EventEmitter();

  @Input() baseUrl: 'PermissionManagement' | 'ContractRegistration';
  @Input() placeholder: any;
  @Input() disabled: boolean;
  @Input() itemList: any[];
  @Input() data: any;
  @Input() entity: any;
  @Input() required: boolean;
  @Input() readOnly: boolean;

  inputCtrl = new FormControl();
  filteredItems: Observable<any[]>;
  isValid: boolean;
  change: SimpleChanges;

  constructor(private restangular: Restangular) { }

  async ngOnInit() {
    if (this.readOnly) { this.inputCtrl.disable(); }
    if (!this.itemList) {
      const service = new BaseService(this.restangular);
      service.setEntity(this.entity);
      if (this.baseUrl) {
        service.setApiType(this.baseUrl);
      }
      let results = await service.findAll({ take: 1000000 });
      results = results.results ?? results.content;
      this.itemList = this.getFilterByStatus(results);
    }
    this.filteredItems = this.inputCtrl.valueChanges.pipe(
      startWith(''),
      map(state => state ? this._filterStates(state) : this.itemList.slice())
    );
    this.setValue(this.data);
    this.isValid = false;
    this.change = null;
    this.onLoad.emit(true);
  }

  ngOnChanges(change: SimpleChanges) {
    const boolValue = change.disabled?.currentValue
    if (boolValue) { this.inputCtrl.disable(); }
    this.change = change;
  }

  ngAfterViewInit() {
    this.trigger.panelClosingActions
      .subscribe(() => {
        const hasChange = this.change
        const isValidPrevious = this.change?.data.previousValue === this.inputCtrl.value
        const isValidCurrent = this.change?.data.currentValue === this.inputCtrl.value

        if (hasChange && (!isValidCurrent && !isValidPrevious)) {
          this.inputCtrl.setValue(null);
          this.trigger.closePanel();
        }
      });

    this.inputCtrl.valueChanges.subscribe(() => {
      const isValidPrevious = this.change?.data?.previousValue
      if (!isUndefined(isValidPrevious)) { this.isValid = false; }
    });
  }

  setValue(value: any) {
    this.inputCtrl.setValue(value)
  }

  loading() {
    return this.itemList.length === 0
  }

  displayFn(marketSegmentId: any) {
    return this.itemList?.find(o => o.id === marketSegmentId)?.name
  }

  private _filterStates(value: string): any[] {
    const filterValue = value.toLowerCase()
    return this.itemList.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0)
  }

  onModelChange(event: any) {
    const checkValidate = (this.isValid === true || isUndefined(this.isValid))
    this.inputCtrl.value && this.onDataChange.emit(this.itemList.find(item => item.id === this.inputCtrl.value));
    this.dataChange.emit((checkValidate ? event : null))
  }

  getSelectedOption(value: any) {
    this.isValid = true;
    this.inputCtrl.setValue(value);
  }

  getFilterByStatus(results: any[]) {
    const statusId = window.localStorage.getItem('statusId');
    switch (this.entity) {
      case 'Company':
      case 'Contract':
      case 'Product':
      case 'Customer':
        return results.filter(item => item.statusId === statusId);
      case 'BusinessMarket':
        return results.filter(item => item.status === 'ATIVO');
      default:
        return results
    }
  }

}
