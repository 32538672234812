import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Cliente } from 'app/legacy/core/model/tenant/cliente';
import { TenantService } from 'app/legacy/core/services/tenant.service';

@Injectable()
export class AuthGuard implements CanActivate {

    private cliente: Cliente;

    constructor(
        private router: Router,
        private tenantService: TenantService
        ) {
            this.cliente = this.tenantService.getCliente();
        }

    canActivate(): boolean {
        if (window.localStorage.getItem('userData') || window.sessionStorage.getItem('userData')) {
            return true;
        } else {
            this.router.navigateByUrl(`${this.cliente.name}/${this.cliente.homePage}`);
            return false;
        }
    }
}
