import { TranslationPipe } from 'app/legacy/core/translation/translation.pipe';
import { ComponentType } from '@angular/cdk/portal'
import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { BaseService } from 'app/legacy/core/services/basic.service'
import { MessagesService } from 'app/legacy/core/services/messages.service'
import { UserService } from 'app/legacy/core/services/user.service'
import { identity, isEmpty, isUndefined, pickBy, snakeCase, uniq } from 'lodash'
import { Restangular } from 'ngx-restangular'
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const NOTIFICATION_TIME = 3000

@Component({
  selector: 'app-crud-list-container',
  templateUrl: './crud-list-container.component.html',
  styleUrls: ['./crud-list-container.component.css']
})
export class CrudListContainerComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() columns: any[];
  @Input() disableSort: boolean;
  @Input() pageTitle: string;
  @Input() hidden: boolean;
  @Input() entityName: string;
  @Input() dialogComponent: ComponentType<Component>;
  @Input() customFilter: any = {};
  @Input() sizeAttrName = 'rowCount';
  @Input() listAttrName = 'results';
  @Input() useListAttrName = true;
  @Input() defaultPageSize = 10;
  @Input() customQuery: string;
  @Input() showFilterBar = true;
  @Input() showFilterName = true;
  @Input() filterProp = 'name';
  @Input() showEmptyMessage = false;
  @Input() message = this.translationPipe.transform('trl_no_results_found_for_this_filter');
  @Input() showAddButton: boolean;
  @Input() showEditButton: boolean;
  @Input() showDeleteButton: boolean;

  subscriptions: Subscription;
  searchFilter$ = new Subject();

  pageEvent: PageEvent;

  showProgressSpinner = true;
  filterValue: string;
  dataLength: number;
  dataList: any;
  data: any;
  storeColumnsKey: string;

  marketSegmentId: any = {};
  businessMarketId: any = {};
  customerId: any = {};
  clientId: any = {};
  contractId: any = {};
  offerTypeId: any = {};
  executionPlanId: any = {};
  customerProductId: any = {};
  journeyId: any = {};
  journeyTypeId: any = {};
  stepId: any = {};
  contractProductId: any = {};
  offerId: any = {};
  serviceTypeId: any = {};
  stepTypeId: any = {};
  serviceId: any = {};
  productId: any = {};
  statusId: any = {};
  businessUnitId: any = {};
  companyId: any = {};

  constructor(
    private service: BaseService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    public restangular: Restangular,
    private messagesService: MessagesService,
    private userService: UserService,
    private translationPipe: TranslationPipe
  ) {
  }

  async ngOnInit() {
    this.service = new BaseService(this.restangular)
    this.service.setEntity(this.entityName)

    this.dataList = new MatTableDataSource([])
    this.dataList.sort = this.sort

    this.storeColumnsKey = '_store-columns-' + this.entityName
    const columnsStoraged = JSON.parse(window.localStorage.getItem(this.storeColumnsKey))

    this.columns = (
      columnsStoraged !== null ?
        this.columns.map((column) => {
          columnsStoraged.map((storage: string) => {
            column.visible = column.id === storage ? true : column.visible;
          })
          return column
        }) :
        this.columns.map(column => {
          column.visible = column.visible ?? true;
          return column
        })
    )

    this.setStoragedColumns()
    this.refreshList()

    if (typeof this.showAddButton === 'undefined') {
      this.showAddButton = true
    }
    if (typeof this.showEditButton === 'undefined') {
      this.showEditButton = await this.userService.hasPermissionToAction(snakeCase(this.entityName), 'edit')
    }
    if (typeof this.showDeleteButton === 'undefined') {
      this.showDeleteButton = await this.userService.hasPermissionToAction(snakeCase(this.entityName), 'delete')
    }

    this.subscriptions = this.searchFilter$.pipe(
      debounceTime(750)).subscribe((name: any) => {
        this.applyFilter(name);
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setStoragedColumns() {
    window.localStorage.setItem(this.storeColumnsKey, JSON.stringify(this.getVisibleColumns()))
  }

  getVisibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.id).concat('options')
  }

  async refreshList(query: any = {}) {
    this.showProgressSpinner = true
    try {

      if (isEmpty(query) && !isUndefined(this.filterValue)) {
        const FilterValue = this.filterValue
        query = { FilterProp: this.filterProp, FilterValue }
      }

      const take = this.paginator?.pageSize || this.defaultPageSize
      const skip = this.paginator?.pageIndex || 0

      const service = new BaseService(this.restangular)
      service.setEntity(this.entityName)

      const filterNotNull = pickBy(this.customFilter, identity);
      const queryParams = { skip, take, type: this.customQuery, ...query, ...filterNotNull }
      for (const key in queryParams) {
        if (Object.prototype.toString.call(queryParams[key]) === '[object Date]') {
          queryParams[key] = queryParams[key].toISOString()
        }
      }

      const data = await service.findAll(queryParams)
      this.dataList.data = this.useListAttrName ? data[this.listAttrName] : data;
      this.dataLength = data[this.sizeAttrName]

      await this.afterRefreshList(this.useListAttrName ? data[this.listAttrName] : data)
    } catch (error) {
      console.error(error)
    } finally {
      this.showProgressSpinner = false
    }
  }

  applyFilter(FilterValue: string) {
    this.paginator.pageIndex = 0;
    this.refreshList({ FilterProp: this.filterProp, FilterValue })
  }

  sortData(sort: Sort) {
    this.refreshList({ OrderBy: sort.active, Direction: sort.direction })
    this.filterValue = ''
  }

  async delete(item: any) {
    if (confirm(`${this.messagesService.trl_are_you_sure_you_want_to_remove}: ${item.name} ?`)) {
      try {
        this.showProgressSpinner = true
        await this.service.delete(item.id)
        this.showNotification(this.messagesService.trl_successfully_removed, 'success')
        await this.refreshList()
      } catch (error) {
        console.error(error)
        this.showNotification(this.messagesService.trl_error_removing, 'error')
      } finally {
        this.showProgressSpinner = false
      }
    }
  }

  add(data: any = {}) {
    let dialogConfig = new MatDialogConfig()
    dialogConfig = { disableClose: true }
    this.service.setDisabled(false);
    dialogConfig.data = Object.assign({ _add: true }, data)
    this.dialog.open(this.dialogComponent, dialogConfig).afterClosed()
      .subscribe(async dialogData => {
        try {
          if (dialogData) {
            this.showProgressSpinner = true
            await this.service.create(dialogData)
            this.showNotification(this.messagesService.trl_saved_successfully, 'success')
          }
        } catch (error) {
          error.data.Message.includes('CNPJ already exists.') ?
            this.showNotification(this.messagesService.trl_alread_exist_cnpj, 'error') :
            this.showNotification(this.messagesService.trl_error_when_trying_to_save, 'error')
          this.add(dialogData)
        } finally {
          await this.refreshList()
        }
      })
  }

  edit(data: any) {
    let dialogConfig = new MatDialogConfig()
    dialogConfig = { disableClose: true }
    this.service.setDisabled(false);
    dialogConfig.data = Object.assign({ _edit: true }, data)
    this.dialog.open(this.dialogComponent, dialogConfig).afterClosed()
      .subscribe(async dialogData => {
        try {
          if (dialogData) {
            this.showProgressSpinner = true
            await this.service.update(dialogData)
            this.showNotification(this.messagesService.trl_saved_successfully, 'success')
          }
        } catch (error) {
          console.error(error)
          this.showNotification(this.messagesService.trl_error_when_trying_to_save, 'error')
        } finally {
          await this.refreshList()
        }
      })
  }


  view(data: any) {
    let dialogConfig = new MatDialogConfig()
    dialogConfig = { disableClose: true }
    this.service.setDisabled(true);
    dialogConfig.data = Object.assign({ _view: true }, data)
    const dialog = this.dialog.open(this.dialogComponent, dialogConfig)
    dialog.afterClosed().subscribe(async dialogData => {
      if (dialogData) {
        try {
          this.showProgressSpinner = true
          await this.service.update(dialogData)
          this.showNotification(this.messagesService.trl_saved_successfully, 'success')
        } catch (error) {
          console.error(error)
          this.showNotification(this.messagesService.trl_error_when_trying_to_save, 'error')
        } finally {
          await this.refreshList()
        }
      }
    })
  }

  async afterRefreshList(list: any[]) {
    try {
      this.marketSegmentId = await this.getColumnsName(list, 'MarketSegment', 'marketSegmentId')
      this.businessMarketId = await this.getColumnsName(list, 'BusinessMarket', 'businessMarketId')
      this.customerId = await this.getColumnsName(list, 'Customer', 'customerId')
      this.clientId = await this.getColumnsName(list, 'Customer', 'clientId')
      this.contractId = await this.getColumnsName(list, 'Contract', 'contractId')
      this.offerTypeId = await this.getColumnsName(list, 'OfferType', 'offerTypeId')
      this.executionPlanId = await this.getColumnsName(list, 'ExecutionPlan', 'executionPlanId')
      this.customerProductId = await this.getColumnsName(list, 'CustomerProduct', 'customerProductId')
      this.journeyId = await this.getColumnsName(list, 'journey', 'JourneyId')
      this.journeyTypeId = await this.getColumnsName(list, 'JourneyType', 'journeyTypeId')
      this.stepId = await this.getColumnsName(list, 'Step', 'stepId')
      this.contractProductId = await this.getColumnsName(list, 'ContractProduct', 'contractProductId')
      this.offerId = await this.getColumnsName(list, 'Offer', 'offerId')
      this.serviceTypeId = await this.getColumnsName(list, 'ServiceType', 'serviceTypeId')
      this.serviceId = await this.getColumnsName(list, 'Service', 'serviceId')
      this.productId = await this.getColumnsName(list, 'Product', 'productId')
      this.statusId = await this.getColumnsName(list, 'Status', 'statusId')
      this.businessUnitId = await this.getColumnsName(list, 'BusinessUnit', 'businessUnitId')
      this.companyId = await this.getColumnsName(list, 'Company', 'companyId')
    } catch (error) {
      console.error(error)
    }
  }

  async getColumnsName(list: any[], entityName: string, attributeName: string) {
    try {
      const idList = {}
      const service = new BaseService(this.restangular)
      service.setEntity(entityName)
      service.setApiType(service.getApiType(entityName, this.entityName))
      for (const id of this.getColumns(attributeName, list)) {
        idList[id] = (await service.getOne(id)).name
      }
      return idList
    } catch (error) {
      console.error(error)
    }
  }

  getColumns(attribute, list: any[] = []) {
    return uniq(list.filter(item => item[attribute]).map(item => item[attribute]))
  }

  getColumnValue(element: any, attr: string) {
    return this[attr] ? this[attr][element[attr]] : element[attr]
  }

  public showNotification(msg, panelClass: 'success' | 'error' | 'info' = 'info') {
    this.snackBar.open(msg, 'OK', {
      duration: NOTIFICATION_TIME,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: `notification-${panelClass}`
    })
  }

}

@Pipe({
  name: 'CNPJ'
})
export class CNPJPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (value.length === 14) {
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return 'error';
  }
}
