import { DatePipe } from '@angular/common'
import { Component, Input, OnChanges, OnInit, Pipe, PipeTransform, SimpleChanges } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { uniq } from 'lodash'
import { Cliente } from './../../core/model/tenant/cliente'
import { TenantService } from './../../core/services/tenant.service'
import { IAppReport } from './app-report.interface'

@Component({
  selector: 'app-report',
  templateUrl: './app-report.component.html',
  styleUrls: ['./app-report.component.css']
})
export class AppReportComponent implements OnInit, OnChanges {

  url: any = `${window.location.origin}/Api`
  cliente: Cliente

  @Input()
  data: IAppReport
  accessToken: any
  groupId: string
  safeUrl: any
  error: string

  constructor(
    public sanitizer: DomSanitizer,
    private tenantService: TenantService,
    private datePipe: DatePipe
  ) {
    this.cliente = this.tenantService.getCliente()
  }

  ngOnInit(): void {
    this.groupId = this.cliente?.powerBi?.groupId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.accessToken = undefined
      this.getTokens()
    }
  }

  async getTokens() {
    this.reportIds.map(async reportId => {
    let reportToken;
    if (window.sessionStorage.getItem(this.cookieName(reportId))) {
      reportToken = JSON.parse(window.sessionStorage.getItem(this.cookieName(reportId)))
    }
    if (!reportToken || reportToken === 'undefined') {
        reportToken = await this.getExternalToken(reportId)
    } else {
      const date = new Date();
      const reportTokenParse = reportToken instanceof Object ? reportToken : JSON.parse(reportToken);
      const expiryDate = this.datePipe.transform(new Date(reportTokenParse.embedTokenExpiry), 'M/dd/yyyy HH:mm:ss', '-0600');
      if (date > new Date(expiryDate)) {
        reportToken = await this.getExternalToken(reportId);
      }
    }
      this.setAccessToken(reportId, reportToken)
    })
  }

  async getExternalToken(reportId: string) {
    try {
      this.error = undefined
      const request = await fetch(`${this.url}/PermissionManagement/embedreport/group/${this.cliente.powerBi?.groupId}/report/${reportId}`)
      if (request.status === 200) {
        const token = await request.json()
        return JSON.stringify(token);
      } else {
        throw request
      }
    } catch (error) {
      this.error = 'Erro ao acessar o relatório.'
      console.log(this.error, error)
    }
  }

  getEmbedUrl(reportId: string, pageName: string) {
    const url = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${this.groupId}&filterPanelEnabled=false&navContentPaneEnabled=false`
    if (pageName) {
      return `${url}&pageName=${pageName}`
    }
    return url
  }

  get reportIds() {
    return uniq(this.data?.reports?.filter(report => report.id).map(report => report.id) || [])
  }

  get reportURLs() {
    return uniq(this.data?.reports?.filter(report => report.url).map(report => report.url) || [])
  }

  setAccessToken(reportId: string, newToken: any) {
    if (!this.accessToken) {
      this.accessToken = {}
    }
    this.accessToken[reportId] = JSON.parse(newToken).accessToken;
    this.error = undefined

    window.sessionStorage.setItem(this.cookieName(reportId), JSON.stringify(newToken));
  }

  cookieName(reportId: string) {
    return `report_token_${reportId}`
  }

}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
