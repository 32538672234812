import { LayoutService } from './../../services/layout.service'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Cliente } from 'app/legacy/core/model/tenant/cliente'
import { BaseService } from 'app/legacy/core/services/basic.service'
import { UserService } from 'app/legacy/core/services/user.service'
import { Restangular } from 'ngx-restangular'
import { Subscription } from 'rxjs'
import { TenantService } from './../../services/tenant.service'
import PerfectScrollbar from 'perfect-scrollbar'

declare const $: any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  cliente: Cliente
  clientUrl: string
  menuItems = []
  statusActiveMenu: any
  filteredMenuItems: any[]
  loggedIn = false
  userData: any
  selectedLang = new FormControl()
  subscriptions: Subscription
  menuService = new BaseService(this.restangular)
  statusService = new BaseService(this.restangular)
  elemSidebar: HTMLElement
  perfectScrollBarMenu: PerfectScrollbar

  constructor(
    public userService: UserService,
    private router: Router,
    private tenantService: TenantService,
    public translateService: TranslateService,
    private restangular: Restangular,
    private layoutService: LayoutService
  ) {}

  async ngOnInit() {
    this.selectedLang.setValue(this.translateService.getDefaultLang())
    this.getMenuUser()
    this.subscriptions = this.selectedLang.valueChanges.subscribe((value) => this.setLanguage(value))
    this.subscriptions.add(this.layoutService.updateMenu$.subscribe(() => this.getMenuUser()))
    this.elemSidebar = document.querySelector('.sidebar .sidebar-wrapper')
    this.perfectScrollBarMenu = new PerfectScrollbar(this.elemSidebar)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async getStatusMenu() {
    this.statusService.setEntity('Status')
    this.statusActiveMenu = await this.statusService.findAll()
    this.statusActiveMenu = this.statusActiveMenu.results.filter((status: any) => status.name === 'ATIVO')[0]
  }

  async getMenuUser() {
    this.menuService.setEntity('Menu')
    await this.getStatusMenu()
    this.menuItems = this.tenantService.getMenusCliente()
    this.cliente = this.tenantService.getCliente()
    this.clientUrl = this.tenantService.getClienteUrl()
    this.userData = await this.userService.getUserData()
    this.filteredMenuItems = await this.getMenuItems()
    this.filteredMenuItems = await this.getDashboardMenu()
  }

  userInfo() {
    this.router.navigate([`${this.cliente.name}/admin/my-profile`])
  }

  changePassword() {
    this.router.navigate([`${this.cliente.name}/session/change-password`])
  }

  login() {
    this.router.navigate([`${this.cliente.name}/session/login`])
  }

  logout() {
    this.userService.signOut()
  }

  setLanguage(language: string) {
    this.translateService.setDefaultLang(language)
    this.translateService.use(language).toPromise()
    localStorage.setItem('user-language', language)
    window.location.reload()
  }

  async getDashboardMenu() {
    const userCustomerId = localStorage.getItem('userCustomerId')
    let params = { skip: 0, take: 10000 }
    if (userCustomerId) {
      params = { ...params, ...{ filterProp: 'customerId', filterValue: userCustomerId } }
    }
    const menuDashboard = await this.menuService.findAll(params)
    if (userCustomerId) {
      this.filteredMenuItems = this.filteredMenuItems.filter(
        (m) =>
          m.title === 'trl_happens_at_WHO' ||
          m.title === 'trl_in_the_media' ||
          m.title === 'trl_customer_portal' ||
          m.title === 'trl_try_it' ||
          m.title === 'trl_onboarding'
      )
      menuDashboard.results = menuDashboard?.results?.filter((m) => m.customerId === userCustomerId)
    }
    let customMenu = menuDashboard.results.filter((menu: any) => menu.statusId === this.statusActiveMenu.id)
    customMenu = customMenu.map((item: any) => {
      if (item.public) {
        return {
          title: item.name,
          id: `dashboard-${item.id}`,
          subMenuItems: item.subMenu
        }
      } else if (this.hasPermission(item.operationId)) {
        const menu = {
          title: item.name,
          id: `dashboard-${item.id}`,
          subMenuItems: this.checkPermissionsSubMenuAndTab(item.subMenu)
        }
        return menu.subMenuItems.length ? menu : null
      }
      return null
    })
    customMenu.forEach((cm: any) => {
      if (cm) {
        this.filteredMenuItems.splice(this.filteredMenuItems.length, 0, cm)
      }
    })
    this.filteredMenuItems.forEach((m) => (m.subMenuItems = m.subMenuItems?.sort((sm1, sm2) => sm1.ordem - sm2.ordem)))
    return this.filteredMenuItems
  }

  checkPermissionsSubMenuAndTab(subMenu: any) {
    const items = []
    subMenu.forEach((sub: any) => {
      if (sub.operationId && this.userData?.module.some((m) => m?.operation.some((o) => o?.id === sub.operationId))) {
        sub.tab = sub.tab.map((t) => {
          if (t.operationId && this.userData?.module.some((m) => m?.operation.some((o) => o?.id === t.operationId))) {
            return t
          }
        })
        items.push(sub)
      }
    })
    return items
  }

  hasPermission(menuOperationId) {
    if (menuOperationId && this.userData) {
      return this.userData.module.some((m) => m?.operation.some((o) => o?.id === menuOperationId))
    }
    return false
  }

  async getMenuItems() {
    if (this.userData?.id) {
      const permissions = await this.userService.getUserPermissions()
      return this.menuItems
        ?.filter((menuItem) => {
          if (menuItem.public || !menuItem.path) {
            return true
          }
          return permissions.findIndex((permission) => menuItem.path.includes(permission)) >= 0
        })
        .map((menuItem) => {
          if (menuItem.subMenuItems) {
            menuItem.subMenuItems = menuItem.subMenuItems.filter(
              (subMenuItem) =>
                subMenuItem.public || permissions.findIndex((permission) => subMenuItem.path.includes(permission)) >= 0
            )
          }
          return menuItem
        })
        .filter((menuItem) => !menuItem.subMenuItems || menuItem.subMenuItems?.length > 0)
    } else {
      return this.menuItems.filter((menu) => menu.public)
    }
  }

  deepSearch(object, key, predicate) {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
      return object
    }

    for (const i of Object.keys(object)) {
      if (typeof object[Object.keys(object)[i]] === 'object') {
        const o = this.deepSearch(object[Object.keys(object)[i]], key, predicate)
        if (o != null) {
          return o
        }
      }
    }
    return null
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false
    }
    return true
  }

  goToDashboard(dashboard) {
    const dashboardActive = localStorage.getItem('tabsDashboardActive')
    if (dashboardActive) {
      localStorage.removeItem('tabsDashboardActive')
    }
    if (dashboard) {
      const tabsData = JSON.stringify(dashboard.tab)
      localStorage.setItem('tabsDashboardActive', tabsData)
      this.router.navigate([`/${this.clientUrl}/custom-menu-content/${dashboard.id}`])
    }
  }
}
