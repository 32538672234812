import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'environments/environment'
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url = '/Api'

  constructor(private http: HttpClient) { }

  public loginPost(data: any): Observable<any> {
    return this.http.post(`${this.url}/PermissionManagement/user/login`, data);
  }

  public newPassword(data: any): Observable<any> {
    return this.http.put(`${this.url}/PermissionManagement/user/password/change`, data);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.put(`${this.url}/PermissionManagement/user/password/reset`, data);
  }

  public forgotPassword(data: any): Observable<any> {
    return this.http.post(`${this.url}/PermissionManagement/user/password/forgot`, data);
  }

  public refreshToken(data: string): Observable<any> {
    return this.http.get(`${this.url}/PermissionManagement/user/refreshtoken?${data}`);
  }

}
