declare interface IMenuItem {
    title: string
    icon?: string
    path?: string
    public?: boolean
    redirectUrl?: string
    reports?: IReport[]
    subMenuItems?: ISubMenuItem[]
    onClick?: Function
}

declare interface ISubMenuItem extends IMenuItem { }

declare interface IReport {
    name?: string
    id?: string
    url?: string,
    section?: string
}

export const MENU_ITEMS: IMenuItem[] = [
    {
        title: 'trl_happens_at_BRT',
        icon: 'dashboard',
        path: '/acontece-na-brt',
        public: true
    }, {
        title: 'trl_cash_management',
        icon: 'work',
        path: '/relatorio/gestao-a-vista',
        reports: [
            {
                id: '8f3b57e7-582e-4cab-a589-9941c40702e2'
            }
        ]
    }, {
        title: 'trl_register',
        icon: 'content_paste',
        subMenuItems: [
            {
                path: '/admin/portfolio',
                title: 'Portfólio',
                icon: 'business_center'
            }, {
                path: '/clientes',
                title: 'trl_client',
                icon: 'face'
            }, {
                path: '/admin/ofertas',
                title: 'trl_offer',
                icon: 'loyalty'
            }, {
                path: '/admin/jornadas',
                title: 'trl_journey',
                icon: 'fast_forward'
            }, {
                path: '/admin/usuarios',
                title: 'trl_user',
                icon: 'verified_user'
            }
        ]
    }, {
        title: 'trl_customer_portal',
        icon: 'account_box',
        subMenuItems: [
            {
                path: '/relatorio/para-voce',
                title: 'Para Você (saiba mais)',
                icon: 'timeline',
                reports: [
                    {
                        name: 'trl_synthetic',
                        id: '6d7749f1-4419-4ca7-8e70-2faddb4ebbd1',
                        section: 'ReportSection'
                    }, {
                        name: 'trl_analytical',
                        id: '6d7749f1-4419-4ca7-8e70-2faddb4ebbd1',
                        section: 'ReportSection13820347885e0da33c6e'
                    }
                ]
            },
            {
                path: '/relatorio/meus-indicadores',
                title: 'Meus Indicadores',
                icon: 'network_check',
                reports: [
                    {
                        name: 'Indicadores de Performance',
                        id: '8ec7a56c-9df7-429b-8bf5-baae9f4ff431',
                        section: 'ReportSection'
                    }, {
                        name: 'Minhas Transações',
                        id: '8ec7a56c-9df7-429b-8bf5-baae9f4ff431',
                        section: 'ReportSection842f324afba50673bc7c'
                    }, {
                        name: 'Meus Contratos',
                        id: '12345'
                    }, {
                        name: 'Minhas Ofertas',
                        id: '12345'
                    }
                ]
            }
        ]
    }, {
        title: 'trl_agent_portal',
        icon: 'record_voice_over',
        subMenuItems: [
            {
                path: '/servicos/solicitacao-servico',
                title: 'Solicitações de Serviço',
            },
            {
                path: '/analise-proposta',
                title: 'trl_analysis_of_proposals',
            },
            {
                title: 'Meus indicadores',
                path: '/meus-indicadores'
            },
        ]
    }, {
        title: 'trl_decision_making',
        icon: 'assessment',
        subMenuItems: [
            {
                path: '/relatorio/big-picture',
                title: 'Big Picture',
                reports: [
                    {
                        name: 'Big Picture',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSection6f94bd7e9561ab5a052b'
                    },
                    {
                        name: 'Big Picture Histórico',
                        id: 'ae491ec2-4628-43ee-8afe-e63cd2c99d95',
                        section: 'ReportSection'
                    }
                ]
            }, {
                path: '/relatorio/nossos-produtos',
                title: 'trl_our_products',
                reports: [
                    {
                        name: 'trl_synthetic',
                        id: '6d7749f1-4419-4ca7-8e70-2faddb4ebbd1',
                        section: 'ReportSection'
                    }, {
                        name: 'trl_analytical',
                        id: '6d7749f1-4419-4ca7-8e70-2faddb4ebbd1',
                        section: 'ReportSection13820347885e0da33c6e'
                    }
                ]
            }, {
                path: '/relatorio/nossos-clientes',
                title: 'trl_our_clients',
                reports: [
                    {
                        name: 'Cliente - Sintético',
                        id: '8ec7a56c-9df7-429b-8bf5-baae9f4ff431',
                        section: 'ReportSection'
                    }, {
                        name: 'Cliente - Analítico',
                        id: '8ec7a56c-9df7-429b-8bf5-baae9f4ff431',
                        section: 'ReportSection842f324afba50673bc7c'
                    }, {
                        name: 'Consignado - Sintético',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSectionae1eff731f6b829b93df'
                    }, {
                        name: 'Consignado - Analítico',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSection1a641db4c161021a6500'
                    }, {
                        name: 'Cartão Internet - Sintético',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSection'
                    }, {
                        name: 'Cartão Internet - Analítico',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSectionb2427e5f370757670aed'
                    }, {
                        name: 'NAC - Sintético',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSection37f87d4672b11014ebdb'
                    }, {
                        name: 'NAC - Analítico',
                        id: '46c9ed90-9b25-4dab-b19b-2fbdf8e33fc8',
                        section: 'ReportSectione29bdcae8d594167b790'
                    }
                ]
            }, {
                path: '/relatorio/processos-operacoes',
                title: 'trl_processes_and_operations',
                reports: [
                    {
                        name: 'Execução - Sintético',
                        id: '9890db67-d8a0-4a29-b042-4c484dbfa643',
                        section: 'ReportSection'
                    }, {
                        name: 'Execução - Analítico',
                        id: '9890db67-d8a0-4a29-b042-4c484dbfa643',
                        section: 'ReportSectionb2427e5f370757670aed'
                    }, {
                        name: 'Propostas Recebidas - Sintético',
                        id: '5ada5870-f01b-4aaf-9673-08aa360b143f',
                        section: 'ReportSection'
                    }, {
                        name: 'Propostas Recebidas - Analítico',
                        id: '5ada5870-f01b-4aaf-9673-08aa360b143f',
                        section: 'ReportSectionfc688eee0a09209fdbf2'
                    }, {
                        name: 'Serviços - Sintético',
                        id: '9819536f-aabf-4859-b132-e468f51b0695',
                        section: 'ReportSection'
                    }, {
                        name: 'Serviços - Analítico',
                        id: '9819536f-aabf-4859-b132-e468f51b0695',
                        section: 'ReportSectione7dd0304bcacc8c3da54'
                    }, {
                        name: 'Transações - Sintético',
                        id: 'b78a485a-0aa5-40cf-940a-f66ddd90059a',
                        section: 'ReportSection'
                    }, {
                        name: 'Transações - Analítico',
                        id: 'b78a485a-0aa5-40cf-940a-f66ddd90059a',
                        section: 'ReportSection07e459eb8d61157b2c67'
                    }
                ]
            }
        ]
    }, {
        title: 'trl_quality_assurance',
        icon: 'bar_chart',
        subMenuItems: [
            {
                path: '/relatorio/qualidade-ml',
                title: 'Performance ML',
                icon: 'network_check',
                reports: [
                    {
                        name: 'trl_overview',
                        id: '062d8e57-1ef8-4b71-95bc-55a10945e80c',
                        section: 'ReportSection'
                    }, {
                        name: 'trl_evolution_models',
                        id: '062d8e57-1ef8-4b71-95bc-55a10945e80c',
                        section: 'ReportSectioncf863e6f3e9dc04250d4'
                    }
                ]
            }, {
                path: '/relatorio/qualidade-bureau',
                title: 'trl_enrichment_data',
                icon: 'timeline',
                reports: [
                    {
                        name: 'trl_overview',
                        id: 'e862424d-723c-4df2-91bf-d9c57a4c36ca',
                        section: 'ReportSection0282c8cd3f3784cc39cf'
                    }, {
                        name: 'Comparação Bureau',
                        id: 'e862424d-723c-4df2-91bf-d9c57a4c36ca',
                        section: 'ReportSection76e3a1827c826b91a6a2'
                    }, {
                        name: 'Consumo dos Bureaus',
                        id: 'e862424d-723c-4df2-91bf-d9c57a4c36ca',
                        section: 'ReportSectiondc8bb78de9c0256db8dd'
                    }
                ]
            }, {
                path: '/relatorio/fraude-maturada',
                title: 'Fraude Maturada',
                icon: 'pan_tool',
                reports: [
                    {
                        url: 'https://app.powerbi.com/view?r=eyJrIjoiZDAwYjc5MzctYjFiYS00NDQ4LTgzNzUtMTBhOTAzZjAzNDEyIiwidCI6IjY1ZWM3YWJlLTFmNjQtNDYxMC1iOTg1LTE2NzIwZjg2NmZjZiJ9'
                    }
                ]
            }, {
                path: '/relatorio/estoque-de-risco',
                title: 'Estoque de Risco',
                icon: 'pageview'
            }
        ]
    }, {
        title: 'Portal do Desenvolvedor',
        icon: 'code',
        onClick: () => window.open('https://eco-dev-apim.developer.azure-api.net/', '_blank')
    }, {
        title: 'trl_ombudsman',
        icon: 'help',
        path: '/wiki'
    },
    {
        title: 'Wiki',
        icon: 'help',
        path: '/wiki'
    }
]
