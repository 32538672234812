import { Component, OnInit } from '@angular/core'
import { TenantService } from 'app/legacy/core/services/tenant.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  date: Date = new Date()
  poweredBy: string
  urlWebSite: string
  urlLinkedIn: string
  urlInstagram: string
  urlFacebook: string

  constructor(private tenantService: TenantService) {}

  ngOnInit() {
    const client = this.tenantService.getCliente()

    this.poweredBy = client.poweredBy
    this.urlWebSite = client.webSite
    this.urlLinkedIn = client.linkedIn
    this.urlInstagram = client.instagram
    this.urlFacebook = client.facebook
  }

  getPoweredBy() {
    return this.poweredBy
  }

  getUrlWebSite() {
    return this.urlWebSite
  }

  getUrlLinkedIn() {
    return this.urlLinkedIn
  }

  getUrlInstagram() {
    return this.urlInstagram
  }

  getUrlFacebook() {
    return this.urlFacebook
  }
}
