import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { BaseCardPageContentComponent } from './base-card-page/base-card-page.component'
import { ChartComponent } from './chart/chart.component'
import { MetricChartComponent } from './metric-chart/metric-chart.component'
import { MetricChartSkeletonComponent } from './metric-chart/skeleton/metric-chart-skeleton.component'

@NgModule({
  imports: [MatCardModule, MatIconModule, MatButtonToggleModule, CommonModule, FormsModule],
  declarations: [BaseCardPageContentComponent, ChartComponent, MetricChartComponent, MetricChartSkeletonComponent],
  exports: [BaseCardPageContentComponent, ChartComponent, MetricChartComponent, MetricChartSkeletonComponent]
})
export class ComponentsModule {}
