import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'app-base-card-page',
  templateUrl: './base-card-page.component.html'
})
export class BaseCardPageContentComponent {
  @HostBinding('class') class = 'tw-contents'

  @Input() cardTitle: string = 'Titulo'
  @Input() iconName: string = 'warning'
}
