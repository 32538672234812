import { Component, Input, OnInit } from '@angular/core';
import { Cliente } from 'app/legacy/core/model/tenant/cliente';
import { LayoutService } from 'app/legacy/core/services/layout.service';
import { TenantService } from 'app/legacy/core/services/tenant.service';

@Component({
  selector: 'app-menu-dropdown-faq',
  templateUrl: './menu-dropdown-faq.component.html',
  styleUrls: ['./menu-dropdown-faq.component.scss']
})
export class MenuDropdownFaqComponent implements OnInit {

  @Input() userData: any;
  public menuHelpOpened = false;
  public client: Cliente;

  constructor(
    private tenantService: TenantService,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.client = this.tenantService.getCliente();
  }

  closeMenuHelp() {
    this.menuHelpOpened = false;
  }

  openOnboarding() {
    localStorage.removeItem('viewOnboarding');
    this.menuHelpOpened = false;
    this.layoutService.showOnboarding();
  }

}
