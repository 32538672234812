import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ComponentsModule } from 'app/components/components.module'
import { CoreModule } from 'app/legacy/core/core.module'
import { SearchMetricTabComponent } from './who-vision-search-metric/components/search-metric-tab/search-metric-tab.component'
import { WhoVisionSearchMetricComponent } from './who-vision-search-metric/who-vision-search-metric.component'

@NgModule({
  imports: [MatIconModule, ComponentsModule, CommonModule, CoreModule],
  declarations: [WhoVisionSearchMetricComponent, SearchMetricTabComponent]
})
export class PagesModule {}
