import { Component, OnInit } from '@angular/core';
import { Cliente } from './../../model/tenant/cliente';
import { TenantService } from './../../services/tenant.service';

@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.scss']
})
export class ClientLogoComponent implements OnInit {

  public cliente: Cliente;

  constructor(
    private tenantService: TenantService
  ) { }

  ngOnInit(): void {
    this.cliente = this.tenantService.getCliente();
  }

  get showLogo() {
    return this.cliente.showClientLogo === true
  }

}
