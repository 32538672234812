import { MediaMatcher } from '@angular/cdk/layout'
import { Location, PopStateEvent } from '@angular/common'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from 'app/legacy/core/services/user.service'
import $ from 'jquery'
import PerfectScrollbar from 'perfect-scrollbar'
import 'rxjs/add/operator/filter'
import { Subscription } from 'rxjs/Subscription'
import { Cliente } from './../../core/model/tenant/cliente'
import { TenantService } from './../../core/services/tenant.service'

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
  public cliente: Cliente
  public menuItems: any
  public orgId: string
  private _router: Subscription
  private lastPoppedUrl: string
  private yScrollStack: number[] = []
  public menuHelpOpened = false
  userCustomerId: string
  perfectScrollBarMainPanel: PerfectScrollbar
  perfectScrollBarMenu: PerfectScrollbar
  elemMainPanel: HTMLElement
  isMobile: MediaQueryList

  selectedLang: string
  paramSubscription: any

  constructor(
    public location: Location,
    private router: Router,
    public translateService: TranslateService,
    private sanitazer: DomSanitizer,
    private userService: UserService,
    private tenantService: TenantService,
    private mediaMatcher: MediaMatcher
  ) {
    this.selectedLang = this.translateService.getDefaultLang()
    this.cliente = this.tenantService.getCliente()
    this.menuItems = this.tenantService.getMenusCliente()
    this.orgId = tenantService.getOrgId()
    this.userCustomerId = localStorage.getItem('userCustomerId')
    this.isMobile = this.mediaMatcher.matchMedia('(max-width: 980px)')
  }

  ngOnInit() {
    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false
    if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
      document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off')
    }
    this.elemMainPanel = document.getElementById('main-panel')
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url
    })
    this.createSubscriptionRoute()
    this.configuteMathMedia()
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.perfectScrollBarMainPanel = new PerfectScrollbar(this.elemMainPanel)
    }
  }

  ngAfterViewInit() {
    this.runOnRouteChange()
  }

  createSubscriptionRoute() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY)
        } else if (event instanceof NavigationEnd) {
          if (event.url === this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined
            window.scrollTo(0, this.yScrollStack.pop())
          } else {
            window.scrollTo(0, 0)
          }
        }
      }
    })
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (this.cliente.appVersion === 'v2') {
          this.configureRecentAccesses(event.url)
        }
        this.elemMainPanel.scrollTop = 0
      })
  }

  configureRecentAccesses(url: string) {
    if (this.userService.getUserData()) {
      if (!url.includes('na-midia') && !url.includes('marketplace/service-detail')) {
        const routeInMenu = this.containsRouteInMenu(url)
        if (routeInMenu) {
          this.setRecentAccessesLocalStorage(routeInMenu)
        } else {
          this.accessesWithoutRouteInMenu(url)
        }
      }
    }
  }

  accessesWithoutRouteInMenu(url: string) {
    if (url.includes('custom-menu-content')) {
      this.containsRouteInIndicators(url)
    } else {
      const title = url.split('/')[url.split('/').length - 1]
      if (title) {
        this.setRecentAccessesLocalStorage({
          title: `trl_${title.replace('-', '_')}`,
          icon: null,
          url: url
        })
      }
    }
  }

  setRecentAccessesLocalStorage(object: any) {
    if (localStorage.getItem('recentAccesses')) {
      const recentAccesses = JSON.parse(localStorage.getItem('recentAccesses'))
      if (!recentAccesses.find((r) => r.url === object.url)) {
        recentAccesses.push(object)
        if (recentAccesses.length > 4) {
          recentAccesses.splice(0, 1)
        }
        localStorage.setItem('recentAccesses', JSON.stringify(recentAccesses))
      }
    } else {
      localStorage.setItem('recentAccesses', JSON.stringify([object]))
    }
  }

  containsRouteInMenu(url: string) {
    const obj = {
      title: null,
      icon: null,
      url: null
    }
    if (this.menuItems.find((m) => m.path === url)) {
      const menu = this.menuItems.find((m) => m.path === url)
      obj.title = menu.title
      obj.icon = menu.icon ?? null
      obj.url = menu.path
    } else if (this.getRouteInSubMenu(url)) {
      obj.title = this.getRouteInSubMenu(url).title
      obj.icon = this.getRouteInSubMenu(url).icon ?? null
      obj.url = this.getRouteInSubMenu(url).path
    } else {
      return null
    }
    return obj
  }

  containsRouteInIndicators(url: string) {
    const indicators = this.userService.menuIndicatorsUser
    const indicatorId = url.split('/')[url.split('/').length - 1]
    const menu = indicators.find((i) => i.id === indicatorId)
    this.setRecentAccessesLocalStorage({
      title: menu.name,
      icon: null,
      url: `/custom-menu-content/${menu.id}`
    })
  }

  getRouteInSubMenu(url: string) {
    const menu = this.menuItems
      .filter((m) => m.subMenuItems?.length)
      .find((m) => m.subMenuItems.find((sm) => sm.path === url))
    return menu?.subMenuItems?.find((sm) => sm.path === url)
  }

  configuteMathMedia() {
    const window_width = $(window).width()
    if (window_width > 767) {
      if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
        $('.fixed-plugin .dropdown').addClass('open')
      }
    }
    $('.fixed-plugin a').click(function (event) {
      if ($(this).hasClass('switch-trigger')) {
        event?.stopPropagation()
      }
    })
    this.fixedPluginClick()
  }

  fixedPluginClick() {
    const $sidebar = $('.sidebar')
    const $sidebar_responsive = $('body > .navbar-collapse')
    const $sidebar_img_container = $sidebar.find('.sidebar-background')
    $('.fixed-plugin .badge').click(function () {
      $(this).siblings().removeClass('active')
      $(this).addClass('active')
      const new_color = $(this).data('color')
      if ($sidebar.length !== 0) {
        $sidebar.attr('data-color', new_color)
      }
      if ($sidebar_responsive.length !== 0) {
        $sidebar_responsive.attr('data-color', new_color)
      }
    })
    $('.fixed-plugin .img-holder').click(function () {
      const $full_page_background = $('.full-page-background')
      $(this).parent('li').siblings().removeClass('active')
      $(this).parent('li').addClass('active')
      const new_image = $(this).find('img').attr('src')
      if ($sidebar_img_container.length !== 0) {
        $sidebar_img_container.fadeOut('fast', function () {
          $sidebar_img_container.css('background-image', 'url("' + new_image + '")')
          $sidebar_img_container.fadeIn('fast')
        })
      }
      if ($full_page_background.length !== 0) {
        $full_page_background.fadeOut('fast', function () {
          $full_page_background.css('background-image', 'url("' + new_image + '")')
          $full_page_background.fadeIn('fast')
        })
      }
      if ($sidebar_responsive.length !== 0) {
        $sidebar_responsive.css('background-image', 'url("' + new_image + '")')
      }
    })
  }

  runOnRouteChange(): void {
    if (!this.isMobile.matches && !this.isMac()) {
      const ps = new PerfectScrollbar(this.elemMainPanel)
      ps.update()
    }
  }

  setRouteDashboard() {
    this.router.navigate([`${this.orgId}/admin/dashboard-customize`])
  }

  isMac(): boolean {
    let bool = false
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true
    }
    return bool
  }

  setLanguage(language: string) {
    this.translateService.setDefaultLang(language)
    this.translateService.use(language).toPromise()
    localStorage.setItem('user-language', language)
    window.location.reload()
  }

  closeMenuHelp() {
    if (this.menuHelpOpened) {
      this.menuHelpOpened = false
    }
  }

  getUrl(url: any) {
    return this.sanitazer.bypassSecurityTrustStyle('url(' + url + ')')
  }
}
