import { Injectable } from '@angular/core'
import { BaseService } from './basic.service'
import { Restangular } from 'ngx-restangular'

@Injectable({
  providedIn: 'root'
})
export class OperationUserService extends BaseService {

  private userOrGroupId: string

  constructor(public restangular: Restangular) {
    super(restangular)
  }

  createOperation(data: any, entity: string, userOrGroupId: any) {
    this.userOrGroupId = userOrGroupId;
    this.setEntity(entity)
    this.create(data)
  }

  create(data: any) {
    return this.provider.one(`/${this.entity}/add/multiple/${this.userOrGroupId}`)
      .customPOST(data).toPromise()
  }

}
