import { BaseService } from './../../../services/basic.service';
import { Injectable } from '@angular/core'
import { Restangular } from 'ngx-restangular'

@Injectable({
  providedIn: 'root'
})
export class StepParamsService extends BaseService {

public baseUrl = `${window.location.origin}/Api`

  constructor(public restangular: Restangular) {
    super(restangular)
  }

  findAllMapNormalization(data: string) {
    this.setEntity('MapNormalization')
    return this.provider.one(`/${this.entity}/${data}`)
        .get().toPromise()
}

}
