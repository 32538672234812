export const getCssVariable = (variable: `--app-${string}`) => {
  const styles = window.getComputedStyle(document.body)
  return styles.getPropertyValue(variable)
}

export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const memoize = <T extends (...args: any[]) => any>(func: T): T => {
  const cache: Record<string, ReturnType<T>> = {}

  return function (this: any, ...args: Parameters<T>): ReturnType<T> {
    const key = JSON.stringify(args)
    if (cache[key] === undefined) {
      cache[key] = func.apply(this, args)
    }

    return cache[key]
  } as T
}

export const delay = (ms: number) => new Promise<void>((res) => setTimeout(res, ms))
