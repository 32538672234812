import { Cliente } from './../../core/model/tenant/cliente';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TenantService } from 'app/legacy/core/services/tenant.service';

@Injectable()
export class UserCustomerGuard implements CanActivate {

    private cliente: Cliente;
    private userCustomerId: string;

    constructor(private router: Router, private tenantService: TenantService) { }

    canActivate(): boolean {
        this.cliente = this.tenantService.getCliente();
        this.userCustomerId = localStorage.getItem('userCustomerId');
        if (this.userCustomerId) {
            this.router.navigateByUrl(`${this.cliente.name}/${this.cliente.homePage}`);
            return false;
        }
        return true;
    }
}
