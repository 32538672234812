import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    private _updateMenu = new Subject<any>();
    updateMenu$ = this._updateMenu.asObservable();

    private _showOnboarding = new Subject<any>();
    showOnboarding$ = this._showOnboarding.asObservable();

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // MENUS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    updateMenu() {
        this._updateMenu.next();
    }

    showOnboarding() {
        this._showOnboarding.next();
    }
}
