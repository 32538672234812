import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from 'app/legacy/core/model/tenant/cliente';
import { TenantService } from 'app/legacy/core/services/tenant.service';
import { UserService } from 'app/legacy/core/services/user.service';

@Component({
  selector: 'app-menu-dropdown-user',
  templateUrl: './menu-dropdown-user.component.html',
  styleUrls: ['./menu-dropdown-user.component.scss']
})
export class MenuDropdownUserComponent implements OnInit {

  @Input() userData: any;
  private client: Cliente;
  public menuOpened = false;

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.client = this.tenantService.getCliente();
  }

  getInitials(string) {
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  goToUserInfo() {
    this.router.navigate([`${this.client.name}/admin/my-profile`]);
    this.closeMenuDropdown();
  }

  goToChangePassword() {
    this.router.navigate([`${this.client.name}/session/change-password`]);
    this.closeMenuDropdown();
  }

  goToLogin() {
    this.router.navigate([`${this.client.name}/session/login`]);
  }

  logout() {
    this.userService.signOut();
    this.closeMenuDropdown();
  }

  closeMenuDropdown() {
    this.menuOpened = false;
  }

}
