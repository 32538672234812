import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { getAllContracts, getAllCustomerProducts, getAllCustomers } from 'app/api/contract.api'
import { getMockedMetricData } from 'app/api/metrics.api'
import Customer, { CustomerProduct } from 'app/models/types/customer'
import { randomIntFromInterval } from 'app/utils/utils'
import { ChartConfiguration } from 'chart.js'

const MAX_DAYS_METRICS_RANGE = 31

type CustomerData = {
  customer: Customer
  products: CustomerProduct[]
}

type DisplayMetricChart = {
  title: string
  chartConfigPromise: Promise<ChartConfiguration>
}

@Component({
  selector: 'app-search-metric-tab',
  templateUrl: './search-metric-tab.component.html'
})
export class SearchMetricTabComponent implements OnInit {
  @Output() clickSearch = new EventEmitter()

  minDate: Date
  maxDate: Date

  customersData: CustomerData[] = []

  listaClientes: Customer[] = []
  listaProdutos: CustomerProduct[] = []

  displayedMetricCharts: DisplayMetricChart[] = []

  searchMetricForm = this.formBuilder.group({
    selectedClient: ['', Validators.required],
    selectedClientProduct: ['', Validators.required],
    periodoVisualizacao: this.formBuilder.group({
      start: [null, Validators.required],
      end: [null, Validators.required]
    })
  })

  constructor(private formBuilder: FormBuilder) {
    const currentDate = new Date()

    this.minDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - MAX_DAYS_METRICS_RANGE
    )
    this.maxDate = currentDate
  }

  ngOnInit(): void {
    this.getAllNecessaryApiDataForForm()

    this.searchMetricForm.get('selectedClient').valueChanges.subscribe((clientId: string) => {
      const customerData = this.customersData.find((d) => d.customer.id === clientId)

      this.listaProdutos = customerData?.products ?? []
    })
  }

  async getAllNecessaryApiDataForForm() {
    const [customers, contracts, customersProducts] = await Promise.all([
      getAllCustomers(),
      getAllContracts(),
      getAllCustomerProducts()
    ])

    this.customersData = customers.results.map((customer) => {
      const contract = contracts.results.find((contract) => contract.customerId === customer.id)
      let products: CustomerProduct[] = []

      if (contract !== undefined) {
        products = customersProducts.results.filter((p) => p.contractId === contract.id)
      }

      return { customer: customer, products: products }
    })

    this.listaClientes = customers.results
  }

  onClickLimparBusca() {
    this.searchMetricForm.reset()
  }

  onClickBuscar() {
    this.displayedMetricCharts = [
      {
        title: 'Abertura do Status do Mailing',
        chartConfigPromise: this.getAberturaStatusMailingMetric()
      },
      {
        title: 'Teste Loading',
        chartConfigPromise: new Promise(() => {})
      }
    ]
  }

  async getAberturaStatusMailingMetric(): Promise<ChartConfiguration> {
    const clientId = String(this.searchMetricForm.get('selectedClient').value)
    const productId = String(this.searchMetricForm.get('selectedClientProduct').value)
    const startPeriod = new Date(this.searchMetricForm.get('periodoVisualizacao.start').value)
    const endPeriod = new Date(this.searchMetricForm.get('periodoVisualizacao.end').value)

    const metric = await getMockedMetricData(productId, 'metric-abertura-status-mailing', startPeriod, endPeriod)

    return {
      type: 'bar',
      data: {
        labels: metric.data.map((d) => d.referenceDate),
        datasets: [
          {
            type: 'bar',
            label: 'Volume de Mailings',
            data: metric.data.map((d) => d.total),
            yAxisID: 'yAxis',
            order: 2
          },
          {
            type: 'line',
            label: '% que atingiram SLA',
            data: metric.data.map((d) => randomIntFromInterval(0, 100)),
            yAxisID: 'percentScale',
            pointRadius: 6,
            order: 1
          }
        ]
      },
      options: {
        scales: {
          yAxis: {
            title: {
              display: true,
              text: 'Volume de Mailings'
            },
            position: 'left'
          },
          percentScale: {
            position: 'right',
            ticks: {
              callback: function (value) {
                return `${value}%`
              }
            }
          }
        }
      }
    }
  }
}
