import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'app/legacy/core/services/basic.service';
import { GroupService } from 'app/legacy/core/services/group.service';
import { StatusPermissionService } from 'app/legacy/core/services/status-permission.service';
import { UserService } from 'app/legacy/core/services/user.service';
import { isEmpty, isNil, uniqBy } from 'lodash';
import { Restangular } from 'ngx-restangular';
import { DownlineTreeviewItem, OrderDownlineTreeviewEventParser, TreeItem, TreeviewComponent, TreeviewEventParser, TreeviewItem } from 'ngx-treeview';
import { OperationUserService } from './../../services/operationUser.service';
import { TranslationPipe } from './../../translation/translation.pipe';

@Component({
  selector: 'app-permissions-select',
  templateUrl: './permissions-select.component.html',
  styleUrls: ['./permissions-select.component.css'],
  providers: [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }
  ]
})
export class PermissionsSelectComponent implements OnInit {

  @ViewChild(TreeviewComponent, { static: false }) treeviewComponent: TreeviewComponent;

  @Input() userId?: any

  @Input() groupId?: any

  @Input() items: any[]

  @Input() disabled: boolean

  operationsSelected: any[] = []
  originalOperations: any[] = []

  modules: any;

  config = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false
  }

  constructor(
    public userService: UserService,
    public groupService: GroupService,
    private restangular: Restangular,
    public translate: TranslationPipe,
    private operationUserService: OperationUserService
  ) { }

  async ngOnInit() {
    await this.getUserPermissions()
    this.getAllPermissions()
  }

  async getAllPermissions() {
    const service = new BaseService(this.restangular);
    service.setEntity('Module');
    const { results: modules = [] } = await service.findAll({ take: 100000 });
    this.modules = modules;

    service.setEntity('Operation')
    let { results: operations = [] } = await service.findAll({ take: 100000 })

    operations = uniqBy(operations, 'name')
    this.items = modules.map((module: any) => {
      return new TreeviewItem({
        text: this.translate.transform(module.name),
        value: module.id,
        disabled: this.disabled,
        children: this.parseChildren(
          operations.filter((operation: any) => operation.moduleId === module.id)
        )
      });
    });
  }

  parseChildren(operations: any[] = []): TreeItem[] {
    return operations.map(operation => ({
      text: this.translate.transform(operation.name),
      value: operation.id,
      children: !isEmpty(operation.childrens) ? this.parseChildren(operation.childrens) : [],
      checked: this.isChecked(operation.id)
    }));
  }

  isChecked(operationId: string): boolean {
    return this.originalOperations.findIndex(o => o.operationId === operationId) >= 0;
  }

  async getUserPermissions() {
    if (!this.userId && !this.groupId && !this.items) {
      console.error('No userId, groupId and itemList');
      return;
    }
    if (!this.items) {
      const { id } = await (this.userId ? this.userService : this.groupService).getAccess(this.userId || this.groupId);
      const service = new BaseService(this.restangular);
      service.setEntity(this.userId ? 'OperationUser' : 'OperationGroup');
      const { results: operationsUserGroup = [] } = await service.findAll({ take: 1000 });
      this.originalOperations = operationsUserGroup.filter(o => o[this.userId ? 'userId' : 'groupId'] === id);
    }
  }

  get loading() {
    return !this.items
  }

  async saveAll(userOrGroupId: any, entity: string) {
    try {
      const permissions = [];
      const service = new BaseService(this.restangular)
      service.setEntity(entity);
      const statusId = await this.getOperationStatusId()
      let newItems = this.operationsSelected.map(operationId => ({
        operationId: operationId,
        [this.userId ? 'userId' : 'groupId']: userOrGroupId,
        createdBy: service.currentUserEmail,
        statusId
      }));
      newItems = newItems.filter(n => this.modules.every(m => n.operationId !== m.id))
      for (const item of newItems) {
        permissions.push(item);
      }
      const data = this.userId ? { operationsUser: permissions } : { OperationsGroup: permissions };
      this.operationUserService.createOperation(data, entity, userOrGroupId);
    } catch (error) {
      console.error(error)
    }
  }

  getOperationStatusId() {
    const service = new StatusPermissionService(this.restangular)
    return service.getDefaultStatusId()
  }

  onSelectedChange(newItems: DownlineTreeviewItem[]) {
    const items = [];
    newItems.forEach(downlineItem => {
      const value = downlineItem.item.value;
      let parent = downlineItem.parent;
      while (!isNil(parent)) {
        items.push(parent.item.value);
        parent = parent.parent;
      }
      items.push(value);
    });
    this.operationsSelected = items
  }

}
