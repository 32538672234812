import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import cliente from 'assets/clientes/eco-brt/cliente.json'
import { clone, map } from 'lodash'
import { Cliente } from './../model/tenant/cliente'
import { TemaCliente } from './../model/tenant/tema-cliente'

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public cliente: Cliente
  public orgId: string
  public translateKeys: any
  public menus: any

  constructor(private meta: Meta) {
    this.cliente = cliente as Cliente
    this.orgId = this.cliente.name
    this.translateKeys = this.cliente.translationKeys
    this.menus = this.addOrgIdPath(this.cliente.menus)
    this.setupTheme(this.cliente.style.theme)
    this.setupHtml()
  }

  getClienteUrl() {
    const urlParts = window.location.href.split('#')
    return urlParts?.length > 1 ? urlParts[1].split('/')[1] : null
  }

  setupTheme(tema: TemaCliente) {
    document.documentElement.style.setProperty('--background-color', tema.backgroundColor)
    document.documentElement.style.setProperty('--primary-color', tema.primaryColor)
    document.documentElement.style.setProperty('--secondary-color', tema.secondaryColor)
    document.documentElement.style.setProperty('--tertiary-color', tema.tertiaryColor)
    document.documentElement.style.setProperty('--link-menu-color', tema.linkMenu)
    document.documentElement.style.setProperty('--menu-selected-color', tema.menuSelected)
    document.documentElement.style.setProperty('--menu-link-selected-color', tema.linkMenuActive)
    document.documentElement.style.setProperty('--menu-collapse-link-selected-color', tema.linkMenuCollapseActive)
    document.documentElement.style.setProperty('--submenu-link-selected-color', tema.linkSubMenuActive)
    document.documentElement.style.setProperty('--main-gradient-color', tema.mainGradientColor)
    document.documentElement.style.setProperty('--background-submenu-active', tema.backgroundSubMenuActive)
  }

  setupHtml() {
    document.querySelector<HTMLLinkElement>('link[rel="icon"]').href = this.cliente.style.favicon
    document.querySelector('title').innerHTML = this.cliente.title
    this.meta.addTag({ name: 'og:description', content: this.cliente.title })
    this.meta.addTag({ name: 'og:title', content: this.cliente.title })
    this.meta.addTag({ name: 'og:image', content: this.cliente.style.favicon })
  }

  addOrgIdPath(listMenu: any[]) {
    const list = map(listMenu, clone)
    return list.map((item) => {
      if (item.path) {
        item.path = `/${this.cliente.name}${item.path}`
      }
      if (item.subMenuItems) {
        item.subMenuItems = this.addOrgIdPath(item.subMenuItems)
      }
      return item
    })
  }

  //// GETTERS
  getCliente(): Cliente {
    return this.cliente
  }

  getMenusCliente() {
    return this.menus
  }

  getOrgId() {
    return this.orgId
  }
}
