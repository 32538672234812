import { Injectable } from '@angular/core'
import Swal, { SweetAlertOptions } from 'sweetalert2'
import { TranslationPipe } from '../translation/translation.pipe'
import { Cliente } from './../model/tenant/cliente'
import { TenantService } from './tenant.service'

@Injectable()
export class SweetAlertService {
  private cliente: Cliente

  constructor(private translationPipe: TranslationPipe, private tenantService: TenantService) {
    this.cliente = this.tenantService.getCliente()
  }

  public warningAlert(options: {
    title: string
    text: string
    isShowCancel?: boolean
    clickEventConfirmButton?: () => void
    clickEventCancelButton?: () => void
  }) {
    Swal.fire({
      title: options.title,
      text: options.text,
      icon: 'warning',
      showCancelButton: options.isShowCancel,
      confirmButtonColor: this.cliente.style.theme.primaryColor,
      cancelButtonColor: this.cliente.style.theme.secondaryColor,
      confirmButtonText: this.translationPipe.transform('trl_confirm_button'),
      cancelButtonText: this.translationPipe.transform('trl_cancel_button'),
      allowOutsideClick: false
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        if (this.isFunction(options.clickEventConfirmButton)) {
          options.clickEventConfirmButton()
        }
      } else {
        if (this.isFunction(options.clickEventCancelButton)) {
          options.clickEventCancelButton()
        }
      }
    })
  }

  private isFunction(x: any): x is (conf: {}) => void {
    return typeof x === 'function'
  }
}
