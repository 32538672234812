import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BaseService } from 'app/legacy/core/services/basic.service'
import { MessagesService } from 'app/legacy/core/services/messages.service'
import { find } from 'lodash'
import { Restangular } from 'ngx-restangular'
import Swal, { SweetAlertOptions } from 'sweetalert2'
import { TranslationPipe } from './../../translation/translation.pipe'

@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.css']
})
export class PriceRangeComponent implements OnInit {
  @Input() priceId?: any

  @Output() priceIdChange = new EventEmitter()

  @Input() disabled = false

  priceRanges: any[] = []

  constructor(
    public translateService: TranslationPipe,
    private restangular: Restangular,
    private messageService: MessagesService
  ) {}

  ngOnInit() {
    this.findPriceRanges()
  }

  add() {
    this.priceRanges.push({})
  }

  async findPriceRanges() {
    if (this.priceId) {
      const service = new BaseService(this.restangular)
      service.setEntity('RangePrice')
      const { results = [] } = await service.findAll({
        FilterProp: 'priceId',
        FilterValue: this.priceId
      })
      this.priceRanges = results
    } else {
      this.add()
    }
  }

  delete(priceRange: any, index: number) {
    if (priceRange?.id) {
      Swal.fire({
        title: this.translateService.transform('trl_delete_price_range'),
        text: this.translateService.transform('trl_confirm_delete_price_range'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        confirmButtonText: this.translateService.transform('trl_confirm_button'),
        cancelButtonText: this.translateService.transform('trl_cancel_button')
      } as SweetAlertOptions).then(async (result) => {
        if (result.value) {
          const serviceRangePrice = new BaseService(this.restangular)
          serviceRangePrice.setEntity('RangePrice')
          await serviceRangePrice.delete(priceRange.id)
          await this.findPriceRanges()
        }
      })
    } else {
      this.priceRanges.splice(index, 1)
      if (this.priceRanges.length === 0) {
        this.add()
      }
    }
  }

  checkPriceRanges() {
    for (let i = 0; i < this.priceRanges.length; i++) {
      if (
        typeof this.priceRanges[i].start === 'undefined' ||
        typeof this.priceRanges[i].end === 'undefined' ||
        typeof this.priceRanges[i].priceValue === 'undefined' ||
        this.priceRanges[i].start === null ||
        this.priceRanges[i].end === null ||
        this.priceRanges[i].priceValue === null ||
        (i === 0 && this.priceRanges[i].start !== 1) ||
        this.priceRanges[i].start >= this.priceRanges[i].end ||
        (this.priceRanges[i - 1] && this.priceRanges[i].start !== this.priceRanges[i - 1].end + 1)
      ) {
        this.priceRanges[i]._error = true
      } else {
        this.priceRanges[i]._error = false
      }
    }

    if (find(this.priceRanges, { _error: true })) {
      const errorMessage = this.translateService.transform('trl_sequence_error')
      throw new Error(errorMessage)
    }
  }

  async saveAll(): Promise<string> {
    this.checkPriceRanges()

    const servicePrice = new BaseService(this.restangular)
    servicePrice.setEntity('Price')

    const serviceRangePrice = new BaseService(this.restangular)
    serviceRangePrice.setEntity('RangePrice')

    if (this.priceId) {
      for (const priceRange of this.priceRanges) {
        if (priceRange?.id) {
          await serviceRangePrice.delete(priceRange.id)
        }
      }
    } else {
      const { id } = await servicePrice.create({})
      this.priceId = id
    }

    for (const priceRange of this.priceRanges) {
      await serviceRangePrice.create({
        ...priceRange,
        priceId: this.priceId
      })
    }

    this.priceIdChange.emit(this.priceId)
    return this.priceId
  }
}
