import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RedirectIfBrt } from './legacy/core/services/route/redirectIfBrt.service'
import { AdminLayoutComponent } from './legacy/layouts/admin-layout/admin-layout.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/who/acontece-na-who',
    pathMatch: 'full'
  },
  {
    path: ':orgId',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./legacy/cruds/cruds.module').then((m) => m.CrudsModule)
      },
      {
        path: 'session',
        loadChildren: () => import('./legacy/auth/auth.module').then((m) => m.AuthModule)
      },
      {
        path: 'acontece-na-who',
        loadChildren: () => import('./legacy/happens-in-brt/happens-in-brt.module').then((m) => m.HappensInBrtModule)
      },
      {
        path: 'relatorio/:reportId',
        loadChildren: () => import('./legacy/reports/reports.module').then((m) => m.ReportsModule)
      },
      {
        path: 'wiki',
        loadChildren: () => import('./legacy/wiki/wiki.module').then((m) => m.WikiModule),
        canActivate: [RedirectIfBrt]
      },
      {
        path: 'developer-portal',
        loadChildren: () =>
          import('./legacy/developer-portal/developer-portal.module').then((m) => m.DeveloperPortalModule)
      },
      {
        path: 'na-midia',
        loadChildren: () => import('./legacy/home/home.module').then((m) => m.HomeModule),
        canActivate: [RedirectIfBrt]
      },
      {
        path: 'servicos',
        loadChildren: () =>
          import('./legacy/service-request/service-request.module').then((m) => m.ServiceRequestModule)
      },
      {
        path: 'visao/buscar-metricas',
        //component: WhoVisionSearchMetricComponent,
        redirectTo: ''
        //canActivate: [AuthGuard]
      },
      {
        path: 'try-it',
        loadChildren: () => import('./legacy/try-it/try-it.module').then((m) => m.TryItModule)
      },
      {
        path: 'cadastro-quiz',
        loadChildren: () => import('./legacy/quiz/quiz.module').then((m) => m.QuizModule)
      },
      {
        path: 'custom-menu-content',
        loadChildren: () =>
          import('./legacy/custom-menu-content/custom-menu-content.module').then((m) => m.CustomMenuContentModule)
      },
      {
        path: 'suporte',
        loadChildren: () => import('./legacy/support/support.module').then((m) => m.SupportModule)
      },
      {
        path: '**',
        redirectTo: `/`
      }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
