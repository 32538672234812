import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-dropdown-language',
  templateUrl: './menu-dropdown-language.component.html',
  styleUrls: ['./menu-dropdown-language.component.scss']
})
export class MenuDropdownLanguageComponent implements OnInit {

  public languages: string[];
  public selectedLang: string;
  public menuOpened = false;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.selectedLang = this.translateService.getDefaultLang();
    this.languages = this.translateService.getLangs();
  }

  setLanguage(language: string) {
    this.translateService.setDefaultLang(language);
    this.translateService.use(language).toPromise();
    localStorage.setItem('user-language', language);
    window.location.reload();
  }

  closeMenuLang() {
    this.menuOpened = false;
  }

}
