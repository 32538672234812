import { getCssVariable } from 'app/utils/utils'
import { Chart, _adapters, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { dateFnsAdapter } from './chartjs-adapter-date-fns'
import { chooseColor, rgba } from './colors'

Chart.register(...registerables, ChartDataLabels)

Chart.defaults.backgroundColor = getCssVariable('--app-primary-default')
Chart.defaults.responsive = true
Chart.defaults.maintainAspectRatio = false
Chart.defaults.plugins.legend.align = 'start'
Chart.defaults.plugins.legend.position = 'bottom'
Chart.defaults.plugins.legend.labels.boxWidth = 12
Chart.defaults.plugins.legend.labels.boxHeight = 12
Chart.defaults.plugins.legend.title.display = true
Chart.defaults.plugins.legend.title.padding = 4

Chart.defaults.plugins.datalabels = {
  display: false
}

Chart.defaults.datasets.line.backgroundColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))
Chart.defaults.datasets.line.borderColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))
Chart.defaults.datasets.line.pointBackgroundColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))

Chart.defaults.datasets.bar.backgroundColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))
Chart.defaults.datasets.bar.borderColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))

Chart.defaults.datasets.radar.backgroundColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))
Chart.defaults.datasets.radar.borderColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))
Chart.defaults.datasets.radar.pointBackgroundColor = (ctx) => rgba(chooseColor(ctx.datasetIndex))

Chart.defaults.datasets.doughnut.backgroundColor = (ctx) => rgba(chooseColor(ctx.dataIndex))
Chart.defaults.datasets.doughnut.borderColor = '#fff'

Chart.defaults.datasets.pie.backgroundColor = (ctx) => rgba(chooseColor(ctx.dataIndex))
Chart.defaults.datasets.pie.borderColor = '#fff'

Chart.defaults.datasets.polarArea.backgroundColor = (ctx) => rgba(chooseColor(ctx.dataIndex))
Chart.defaults.datasets.polarArea.borderColor = (ctx) => rgba(chooseColor(ctx.dataIndex))

Chart.defaults.datasets.bubble.backgroundColor = (ctx) => rgba(chooseColor(ctx.dataIndex))
Chart.defaults.datasets.bubble.borderColor = '#fff'

Chart.defaults.datasets.scatter.backgroundColor = (ctx) => rgba(chooseColor(ctx.dataIndex))
Chart.defaults.datasets.scatter.borderColor = '#fff'

_adapters._date.override(dateFnsAdapter)

export { Chart }
