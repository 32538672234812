import { Component, Input } from '@angular/core'
import { ChartHeightValue } from 'app/components/chart/config/utils'

@Component({
  selector: 'app-metric-chart-skeleton',
  templateUrl: './metric-chart-skeleton.component.html'
})
export class MetricChartSkeletonComponent {
  @Input() public chartHeight: ChartHeightValue = '400px'
}
