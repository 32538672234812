import Customer, { Contract, CustomerProduct } from 'app/models/types/customer'
import PagedResult from 'app/models/types/paged-result'
import api from './api'

export const getAllCustomers = async (): Promise<PagedResult<Customer>> => {
  const response = await api.get<PagedResult<Customer>>('/ContractRegistration/Customer')

  return response.data
}

export const getAllCustomerProducts = async () => {
  const response = await api.get<PagedResult<CustomerProduct>>('/ContractRegistration/CustomerProduct')

  return response.data
}

export const getAllContracts = async () => {
  const response = await api.get<PagedResult<Contract>>('/ContractRegistration/Contract')

  return response.data
}
