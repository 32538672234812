import { Component, Input } from '@angular/core'
import { ChartConfiguration } from 'chart.js'
import { ChartHeightValue } from '../chart/config/utils'

@Component({
  selector: 'app-metric-chart',
  templateUrl: './metric-chart.component.html'
})
export class MetricChartComponent {
  @Input() public chartTitle: string = 'Titulo'
  @Input() public chartConfig!: ChartConfiguration
  @Input() public chartHeight: ChartHeightValue = '400px'

  displayType: 'dia' | 'hora' = 'dia'
}
