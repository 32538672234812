import { Injectable } from '@angular/core'
import { Restangular } from 'ngx-restangular'
import { BaseService } from './basic.service'

@Injectable({
  providedIn: 'root'
})
export class StatusPermissionService extends BaseService {

  constructor(public restangular: Restangular) {
    super(restangular)
    this.setEntity('Status')
    this.setApiType('PermissionManagement')
  }

  async getDefaultStatusId() {
    const { results: allStatus = [] } = await this.findAll({ take: 1000000 })
    return (allStatus.find(status => status.name.toUpperCase() === 'ATIVO') || allStatus[0]).id
  }

}
